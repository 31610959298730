import React from "react";
import { isMobile } from "react-device-detect";
import { Container, Grid } from "semantic-ui-react";

function LocationGroups() {
  const renderGroup = (item) => (
    <div className="group-item-wrapper">
      <a href={item.link}>
        <div className="group-item">
          <img alt="Sliea" rounded src={item.img} style={styles.image} />
          <div className="group-item-title">{item.title}</div>
        </div>
      </a>
      <div style={{ marginTop: 10 }}>
        <Grid columns={4} container>
          {item.cities.map((city, i) => (
            <Grid.Column
              className="city"
              textAlign={"left"}
              key={"city-" + city.name + "-" + i}
              href={city.link}
            >
              {city.name}
            </Grid.Column>
          ))}
        </Grid>
      </div>
    </div>
  );

  return (
    <Container
      style={{ textAlign: "center", marginTop: "2vh", color: "#353a47" }}
    >
      <h1 className="pc-title">Best Locations of Malta</h1>

      <Grid>
        <Grid.Row columns={isMobile ? 1 : 3}>
          <Grid.Column>{renderGroup(centralMalta)}</Grid.Column>
          {isMobile ? <div style={{ height: "2rem", width: "10px" }} /> : null}
          <Grid.Column>{renderGroup(sliema)}</Grid.Column>
          {isMobile ? <div style={{ height: "2rem", width: "10px" }} /> : null}
          <Grid.Column>{renderGroup(southMalta)}</Grid.Column>
        </Grid.Row>
        <Grid.Row columns={isMobile ? 1 : 2}>
          {isMobile ? <div style={{ height: "2rem", width: "10px" }} /> : null}
          <Grid.Column>{renderGroup(northMalta)}</Grid.Column>

          {isMobile ? <div style={{ height: "2rem", width: "10px" }} /> : null}
          <Grid.Column>{renderGroup(gozo)}</Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default LocationGroups;

const styles = {
  image: {
    width: "100%",
    height: 200,
    objectFit: "cover",
    borderRadius: "5px",
  },
};

const centralMalta = {
  title: "Central Malta",
  img: "/img/home/central.jpeg",
  link: "https://www.easyrent.com.mt/property-for-rent-in-cental-malta",
  cities: [
    {
      name: "Attard",
      link: "https://easyrent.com.mt/property-for-rent-in-attard",
    },
    {
      name: "Balzan",
      link: "https://easyrent.com.mt/property-for-rent-in-balzan",
    },
    {
      name: "Bidnija",
      link: "https://easyrent.com.mt/property-for-rent-in-bidnija",
    },
    {
      name: "Birguma",
      link: "https://easyrent.com.mt/property-for-rent-in-birguma",
    },
    {
      name: "Birkirkara",
      link: "https://easyrent.com.mt/property-for-rent-in-birkirkara",
    },
    {
      name: "Dingli",
      link: "https://easyrent.com.mt/property-for-rent-in-dingli",
    },
    {
      name: "Gharghur",
      link: "https://easyrent.com.mt/property-for-rent-in-gharghur",
    },
    {
      name: "Iklin",
      link: "https://easyrent.com.mt/property-for-rent-in-iklin",
    },
    {
      name: "Lija",
      link: "https://easyrent.com.mt/property-for-rent-in-lija",
    },
    {
      name: "Mdina",
      link: "https://easyrent.com.mt/property-for-rent-in-mdina",
    },
    {
      name: "Mosta",
      link: "https://easyrent.com.mt/property-for-rent-in-mosta",
    },
    {
      name: "Mriehel",
      link: "https://easyrent.com.mt/property-for-rent-in-mriehel",
    },
  ],
};

const sliema = {
  title: "Sliema",
  img: "/img/home/sliema.jpeg",
  link: "https://www.easyrent.com.mt/property-for-rent-in-sliema",
  cities: [
    {
      name: "Bahar ic-Caghaq",
      link: "https://easyrent.com.mt/property-for-rent-in-bahar-ic-caghaq",
    },
    {
      name: "Floriana",
      link: "https://easyrent.com.mt/property-for-rent-in-floriana",
    },
    {
      name: "Gzira",
      link: "https://easyrent.com.mt/property-for-rent-in-gzira",
    },
    {
      name: "Hamrun",
      link: "https://easyrent.com.mt/property-for-rent-in-hamrun",
    },
    {
      name: "High Ridge",
      link: "https://easyrent.com.mt/property-for-rent-in-high-ridge",
    },
    {
      name: "Kappara",
      link: "https://easyrent.com.mt/property-for-rent-in-kappara",
    },
    {
      name: "Madliena",
      link: "https://easyrent.com.mt/property-for-rent-in-madliena",
    },
    {
      name: "Msida",
      link: "https://easyrent.com.mt/property-for-rent-in-msida",
    },
    {
      name: "Paceville",
      link: "https://easyrent.com.mt/property-for-rent-in-paceville",
    },
    {
      name: "Pembroke",
      link: "https://easyrent.com.mt/property-for-rent-in-pembroke",
    },
    {
      name: "Pieta",
      link: "https://easyrent.com.mt/property-for-rent-in-pieta",
    },
    {
      name: "San Gwann",
      link: "https://easyrent.com.mt/property-for-rent-in-san-gwann",
    },
  ],
};

const southMalta = {
  title: "South Malta",
  img: "/img/home/south-malta.jpeg",
  link: "https://easyrent.com.mt/property-for-rent-in-south-malta",
  cities: [
    {
      name: "Birgu",
      link: "https://easyrent.com.mt/property-for-rent-in-birgu",
    },
    {
      name: "Birzebbuga",
      link: "https://easyrent.com.mt/property-for-rent-in-birzebbuga",
    },
    {
      name: "Bormla",
      link: "https://easyrent.com.mt/property-for-rent-in-bormla",
    },
    {
      name: "Cospicua",
      link: "https://easyrent.com.mt/property-for-rent-in-cospicua",
    },
    {
      name: "Fgura",
      link: "https://easyrent.com.mt/property-for-rent-in-fgura",
    },
    {
      name: "Ghaxaq",
      link: "https://easyrent.com.mt/property-for-rent-in-ghaxaq",
    },
    {
      name: "Gudja",
      link: "https://easyrent.com.mt/property-for-rent-in-gudja",
    },
    {
      name: "Isla",
      link: "https://easyrent.com.mt/property-for-rent-in-isla",
    },
    {
      name: "Kalkara",
      link: "https://easyrent.com.mt/property-for-rent-in-kalkara",
    },
    {
      name: "Kirkop",
      link: "https://easyrent.com.mt/property-for-rent-in-kirkop",
    },
    {
      name: "Luqa",
      link: "https://easyrent.com.mt/property-for-rent-in-luqa",
    },
    {
      name: "Marsa",
      link: "https://easyrent.com.mt/property-for-rent-in-marsa",
    },
  ],
};

const northMalta = {
  title: "North Malta",
  img: "/img/home/north-malta.jpeg",
  link: "https://easyrent.com.mt/property-for-rent-in-north-malta",
  cities: [
    {
      name: "Bahrija",
      link: "https://easyrent.com.mt/property-for-rent-in-bahrija",
    },
    {
      name: "Bugibba",
      link: "https://easyrent.com.mt/property-for-rent-in-bugibba",
    },
    {
      name: "Burmarrad",
      link: "https://easyrent.com.mt/property-for-rent-in-burmarrad",
    },
    {
      name: "Ghadira",
      link: "https://easyrent.com.mt/property-for-rent-in-ghadira",
    },
    {
      name: "Manikata",
      link: "https://easyrent.com.mt/property-for-rent-in-manikata",
    },
    {
      name: "Mellieha",
      link: "https://easyrent.com.mt/property-for-rent-in-mellieha",
    },
    {
      name: "Mgarr",
      link: "https://easyrent.com.mt/property-for-rent-in-mgarr",
    },
    {
      name: "Qawra",
      link: "https://easyrent.com.mt/property-for-rent-in-qawra",
    },
    {
      name: "Salina",
      link: "https://easyrent.com.mt/property-for-rent-in-salina",
    },
    {
      name: "St Pauls Bay",
      link: "https://easyrent.com.mt/property-for-rent-in-st-pauls-bay",
    },
    {
      name: "Wardija",
      link: "https://easyrent.com.mt/property-for-rent-in-wardija",
    },
    {
      name: "Xemxija",
      link: "https://easyrent.com.mt/property-for-rent-in-xemxija",
    },
  ],
};

const gozo = {
  title: "Gozo",
  img: "/img/home/gozo.jpeg",
  link: "https://www.easyrent.com.mt/property-for-rent-in-gozo",
  cities: [
    {
      name: "Xaghra",
      link: "https://easyrent.com.mt/property-for-rent-in-xaghra",
    },
    {
      name: "Fontana",
      link: "https://easyrent.com.mt/property-for-rent-in-fontana",
    },
    {
      name: "Ghajnsielem",
      link: "https://easyrent.com.mt/property-for-rent-in-ghajnsielem",
    },
    {
      name: "Gharb",
      link: "https://easyrent.com.mt/property-for-rent-in-gharb",
    },
    {
      name: "Ghasri",
      link: "https://easyrent.com.mt/property-for-rent-in-ghasri",
    },
    {
      name: "Kercem",
      link: "https://easyrent.com.mt/property-for-rent-in-ercem",
    },
    {
      name: "Marsalforn",
      link: "https://easyrent.com.mt/property-for-rent-in-marsalforn",
    },
    {
      name: "Munxar",
      link: "https://easyrent.com.mt/property-for-rent-in-munxar",
    },
    {
      name: "Nadur",
      link: "https://easyrent.com.mt/property-for-rent-in-nadur",
    },
    {
      name: "Qala",
      link: "https://easyrent.com.mt/property-for-rent-in-qala",
    },
    {
      name: "Rabat (Victoria)",
      link: "https://easyrent.com.mt/property-for-rent-in-rabat",
    },
    {
      name: "San Lawrenz",
      link: "https://easyrent.com.mt/property-for-rent-in-san-lawrenz",
    },
  ],
};
