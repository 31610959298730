import React, { Suspense } from "react";
import {
  Container,
  Grid,
  Breadcrumb,
  Dropdown,
  Icon,
  Ref,
  Pagination,
  Loader,
  Rail,
  Sticky,
  Menu,
  Button
} from "semantic-ui-react";
import { isMobile, MobileView } from "react-device-detect";
import FilterForm from "./FilterForm";
import ListItem from "./ListItem";
import MostPopularPropertyTypes from "./MostPopularPropertyTypes";
import { getCookie, setCookie } from "../../helpers/cookie";
import { pageEnum, sortingTypeEum } from "../../Common";
import { getListingName } from "../../helpers/common";

const contextRef = React.createRef();

const List = (props) => {
  let pageNumber = getCookie("listingPageNumber");
  if (pageNumber == null || pageNumber == "") {
    pageNumber = 0;
  }

  const [filterAccordion, setFilterAccordion] = React.useState(false);
  const [activePage, setActivePage] = React.useState(pageNumber);
  const [filterFormData, setFilterFormData] = React.useState();
  const [lettingType, setLettingType] = React.useState(null);
  const [sortingType, setSortingType] = React.useState(sortingTypeEum.Default);
  const [labelSort, setLabelSort] = React.useState("Sort");
  const [mapOpen, setMapOpen] = React.useState(false);
  const [form, setForm] = React.useState({});
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  // const ListItem = React.lazy(() => import('./ListItem'))

  const filter = (filterData) => {
    setActivePage(0);
    props.getProperties(0, filterData);
    setFilterFormData(filterData);
    setCookie("listingPageNumber", 0, 1);
  };

  const renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Section link>Home</Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active>Sale</Breadcrumb.Section>
    </Breadcrumb>
  );

  const getPageName = () => {
    if (props.category) {
      return props.category.name;
    }
    switch (window.location.pathname) {
      case "/commercials":
        return "Commercial Properties in Malta";
      case "/long-lets":
        return "Malta Long Let Properties";
      case "/short-lets":
        return "Short Let Properties in Malta";
      case "/room":
        return "Rooms in Malta";
      case "/sales":
        return "Property For Sale in Malta";
      default:
        return "";
    }
  };

  const renderFilterBar = (sticky = false) => {
    if (sticky) {
      {
        /* Mobile */
      }
      return (
        <Rail position="top">
          <Sticky>
            <Menu fixed="top" style={{ marginTop: "12%" }}>
              <Grid style={styles(mapOpen).listHeader}>
                {!isMobile ? null : (
                  <Grid.Column mobile="5">
                    <a
                      class="mb-filter-button"
                      onClick={() => {
                        setFilterAccordion(!filterAccordion);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <Icon name="filter" /> Filter
                    </a>
                  </Grid.Column>
                )}
                <Grid.Column computer={8} mobile="5">
                  <Icon name="sort alphabet ascending" />
                  <Dropdown text={labelSort}>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        text="Newest"
                        onClick={() => {
                          setLabelSort("Sort");
                          setSortingType(sortingTypeEum.Default);
                        }}
                      />
                      <Dropdown.Item
                        text="Price Low to High"
                        onClick={() => {
                          setLabelSort("Price Low to High");
                          setSortingType(sortingTypeEum.PriceLowToHigh);
                        }}
                      />
                      <Dropdown.Item
                        text="Price High to Low"
                        onClick={() => {
                          setLabelSort("Price High to Low");
                          setSortingType(sortingTypeEum.PriceHighToLow);
                        }}
                      />
                      <Dropdown.Item
                        text="Popular"
                        onClick={() => {
                          setLabelSort("Popular");
                          setSortingType(sortingTypeEum.Popular);
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Column>
                <Grid.Column computer={8} mobile={"6"} textAlign="right">
                  <a
                    style={{
                      pointerEvents: props.propertiesLoading ? "none" : "unset",
                    }}
                    onClick={() => {
                      props.setMapVisible();
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    Map View <Icon name="map marker alternate" size={"large"} />
                  </a>
                </Grid.Column>
              </Grid>
            </Menu>
          </Sticky>
        </Rail>
      );
    }

    {
      /* Desktop */
    }
    return (
      <Menu
        fixed={mapOpen ? "" : "top"}
        style={{
          marginTop: mapOpen ? "" : "11rem",
          marginLeft: mapOpen ? "" : "19%",
          width: mapOpen ? "" : "80%",
        }}
      >
        <Grid style={styles(mapOpen).listHeader}>
          {!isMobile ? null : (
            <Grid.Column mobile="5">
              <a
                class="mb-filter-button"
                onClick={() => {
                  setFilterAccordion(!filterAccordion);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <Icon name="filter" /> Filter
              </a>
            </Grid.Column>
          )}
          <Grid.Column computer={8} mobile="5">
            <Icon name="sort alphabet ascending" />
            <Dropdown text={labelSort}>
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Newest"
                  onClick={() => {
                    setLabelSort("Sort");
                    setSortingType(sortingTypeEum.Default);
                  }}
                />
                <Dropdown.Item
                  text="Price Low to High"
                  onClick={() => {
                    setLabelSort("Price Low to High");
                    setSortingType(sortingTypeEum.PriceLowToHigh);
                  }}
                />
                <Dropdown.Item
                  text="Price High to Low"
                  onClick={() => {
                    setLabelSort("Price High to Low");
                    setSortingType(sortingTypeEum.PriceHighToLow);
                  }}
                />
                <Dropdown.Item
                  text="Popular"
                  onClick={() => {
                    setLabelSort("Popular");
                    setSortingType(sortingTypeEum.Popular);
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
          <Grid.Column computer={8} mobile={"6"} textAlign="right">
            <a
              style={{
                pointerEvents: props.propertiesLoading ? "none" : "unset",
              }}
              onClick={() => {
                props.setMapVisible();
                setMapOpen(!mapOpen);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Map View <Icon name="map marker alternate" size={"large"} />
            </a>
          </Grid.Column>
        </Grid>
      </Menu>
    );
  };

  //Sale Properties
  return (
    <Container style={styles(mapOpen).container} fluid>
      <div>
        {/* {renderBreadcrumb()} */}
        <h1
          style={{
            fontSize: isMobile ? "1.5rem" : null,
            marginTop: isMobile ? 50 : 0,
          }}
        >
          {getPageName()}
        </h1>
      </div>
      <div style={{ height: isMobile ? 0 : 15 }} />
      <Ref innerRef={contextRef}>
        <Grid>
          <Grid.Row>
            {isMobile ? null : (
              <Grid.Column computer={3}>
                {/* <Sticky context={contextRef} offset={100}> */}
                <FilterForm
                  options={props.filterOptions}
                  filter={filter}
                  forms={props.form}
                  setFilterForm={props.setFilterForm}
                  setLettingType={setLettingType}
                  pageNumber={props.pageNumber}
                  sortingType={sortingType}
                  category={props.category}
                />
                {/* </Sticky> */}
              </Grid.Column>
            )}
            <Grid.Column computer={13} mobile={16}>
              {renderFilterBar(isMobile)}
              <MobileView>
                {/* {filterAccordion ? */}
                <div style={{ display: filterAccordion ? "block" : "none" }}>
                  <FilterForm
                    options={props.filterOptions}
                    filter={filter}
                    forms={props.form}
                    setFilterForm={props.setFilterForm}
                    setLettingType={setLettingType}
                    pageNumber={props.pageNumber}
                    sortingType={sortingType}
                    setFilterAccordion={() =>
                      setFilterAccordion(!filterAccordion)
                    }
                    category={props.category}
                  />
                </div>
                {/* /> : null} */}
              </MobileView>

              <Container fluid style={styles(mapOpen).items}>
                {props.propertiesLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <Loader active inline />
                  </div>
                ) : null}
                <>
                  <Grid>
                    <Grid.Row>
                      {props.properties?.map((item, index) => (
                        <Grid.Column
                          width="16"
                          computer={4}
                          mobile={16}
                          key={"property-" + item.id}
                        >
                          {/* <Suspense fallback={<div></div>}> */}
                          <ListItem {...item} />
                          {/* </Suspense> */}
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                </>

                <Pagination
                  style={{ width: "100%" }}
                  // defaultActivePage={activePage}//berk yorum satına aldı
                  totalPages={Math.ceil(props.total / 20)}
                  activePage={activePage + 1}
                  ellipsisItem={true}
                  onPageChange={(e, { activePage }) => {
                    if (activePage <= 0) {
                      return;
                    }
                    let pageIndex = activePage - 1;
                    props.getProperties(pageIndex, filterFormData);
                    setActivePage(pageIndex);
                    setCookie("listingPageNumber", pageIndex, 1);
                    window.scrollTo(0, 0);
                  }}
                />
                {props.category && props.category.description && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.category.description,
                      }}
                    ></div>
                  </div>
                )}
                {window.location.pathname === "/long-lets" && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={{ 
                        __html: "<h2>Malta Long Let Properties</h2>" +
                        "<p>Finding a rental property has become very difficult in today's conditions. Therefore, it is necessary to take new steps for these works. On this site you can find a home for any type you are looking for. You can find it down to the smallest detail. Since today is the age of technology, no one wants to go and look or deal with people as interlocutors. Everyone wants to get things done with one click. Thanks to this site, everything is at your fingertips with one click. It will be of great convenience to you because you will not waste time. When you decide, you click on a few things and get the job done. So, this process will not take even an hour of your time. Long lets in Malta refer to a minimum rental period of six months or more, and the most highly prized property types have become usually Apartments and Penthouses, but we already have an impressive array of luxury Resorts with swimming pools, traditional Dwellings of Character (with their wonderful architectural features), and of course other listing such as Townhouses, Terraced Houses, and Maisonettes.</p>" +
                        "<h2>History and Overview of the Malta Property Market</h2>" +
                        "<p>Malta's housing sector has experienced exceptional expansion in recent years. The industry has shown consistent increase in both the quantity of property transactions and overall volume over the last five years. The number of foreigners migrating to Malta or purchasing property is largely responsible for this increase. The rental market has been as successful, with owning rental property viewed as a viable company with a high return on investment.</p>" +
                        "<h2>Malta Property Types</h2>" +
                        "<p>Malta's diversified property market implies that there are numerous property kinds available. You may be confident that you will be able to find high quality real estate in Malta. Today, most of Republic of Malta property investment is being purchased by foreigners and decorated in vintage style, ranging from apartments, maisonettes, and character houses to farmhouses, housing estates, villas, and bungalows. Malta is well known for combining traditional Maltese buildings with a modern approach.</p>" +
                        "<h2>Long Term Rental Properties in Malta</h2>" +
                        "<p>Malta Property is the spot to go if you want to rent a home in Malta. Browse our vast selection of properties in Malta and Gozo, including apartments, villas, farmhouses, and maisonettes, all with or without sea views. Luxury villas are also available at Tigne Point and Portomaso Marina. Long term rentals are typically for further than six months. Our database has over 10,000 houses available for rent in Malta and Gozo.</p>" +
                        "<h2>What are the Property Prices in Malta like?</h2>" +
                        "<p>Purchasing property in Malta remains popular, with home ownership accounting for 80 percent of the Maltese Islands. The demand for apartments has surged in the previous decade, both for domestic use and as rental investments. For the past three years, Maltese property has been on the rise. These have been attributed by the Central Bank to Malta's Individual Investor Programme and the stamp duty remission for first time purchasers.</p>",
                      }}
                    ></div>
                  </div>
                )}

                {window.location.pathname === "/room" && (
                  <div style={{ marginTop: 40 }}>
                    <Button style={styles.button} color ="green" content={"Find Flat Mate"} onClick={() => openInNewTab('./flatmate')} />
                    <div
                      dangerouslySetInnerHTML={{ 
                        __html: "</br><h2>Rooms in Malta</h2>"+
                        "<p>Rooms in Malta offer you accommodation for as long as you want. You can rent daily rooms on a monthly basis over the long term. <b>Single room for rent in Malta</b> there is tiny rooms where you can come to visit and stay for a few days."+
                        "<h2>Malta's Cost of Living</h2>"+
                        "<p>When it comes to culture in Europe, Malta one of the cheaper destinations. You'll pay a little more on bottled water (Malta's water is safe to drink, but the flavour is not great, so everyone buys bottled nor filters their water), but the cost of living in Malta is equivalent to, or lower than, that of other European countries. </p>"+
                        "<p>The cost of living will vary based on where you wish to reside and your lifestyle, but prices are generally inexpensive here. A one bedroom apartment, especially one near the beach, costs roughly $750 to $800 per month on average. Groceries are reasonably priced, with basic things like bread costing roughly 35 cents. A decent mid range restaurant dinner for two, with a bottle of wine, can cost around $50. </p>"+
                        "<p>Full day bus tickets are less than $2 per person (and 30 day bus tickets are $28). According to one soul care couple living in Sliema, groceries, even from organic or health food stores, cost less than $500 a month. Senior citizens have it even easier, with 50 cent all day passages and free dock rides. </p>"+
                        "<p>Those looking to buy property will also find that prices are fair. A stunning three-bedroom, two-bathroom seafront apartment with a big, shared living area facing Xlendi Bay is on the market for $358,000. Smaller properties, of course, are less expensive. <b>Malta room for rent long term</b>, daily rooms are available for long-term rental. You can make monthly deals for the daily room. <b>Cheap rooms to rent in Malta</b> are suitable for all budgets. Finding the cheapest flat of the type you want is very easy thanks to the internet. </p>"+
                        "<h2>Life in Malta</h2>"+
                        "<p>Rent was among the most significant and variable expenditures of living in this city. A couple can locate a flat for less than €700 per month if they chose an older apartment outside of the popular regions. </p>"+
                        "<p>A brand new complex in Sliema or St. Julian's might cost well over €1,500 per month. However, keep in mind that practically all flats in Malta are recently renovated, so that takes care of the cost. The average utility cost (water, electricity, fuel, and internet) of €150 - €200 may appear excessive, but keep in mind that you do not pay income taxes in Malta for items like sewer & infrastructure upkeep. </p>"+
                        "<p>Also, remember that Malta becomes quite hot in the summer, and most buildings are inadequately insulated against frigid winter days, so your utility cost may increase in January, February, July, and August. </p>"+
                        "<p>The Maltese administration is working hard to improve public transportation, and where they have succeeded, you may obtain decent service at a moderate cost. Fares range from €1.50 to €3.00 each way based on the weather (winter, summer, and late night), however bear in mind that transfers are unrestricted within two hours. The monthly bus pass costs €39. The cost of free rides for 7 days is €21 for adults and €15 for minors. <b>Shared apartments Malta</b> is used more often. Because students or families who come to visit are crowded. For this reason, <b>Shared apartments Malta</b> is preferred more often for multi-person groups. </p>"
                      }}
                    ></div>
                  </div>
                )}

                {window.location.pathname === "/commercials" && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={{ 
                        __html: "<h2>Commercial Properties in Malta</h2>"+
                        "<p>Today, people earn their living by working. That's why we see shops everywhere as we walk down the street. People buy or rent these places and turn them into a business, that is, a business. Here they make money by making sales. It is easier to live and trade in Malta than elsewhere. When you come and see it, your mouth will be open against the cheapness. For this reason, <b>shops for rent in Malta</b> are cheap. Of course, it depends on what kind of shop you want. So, it can be cheap, or it can be expensive. But you might say, how expensive can it be? It will be very nice to live here and do business. </p>"+
                        "<h2>Buying and Renting Real Estate</h2>"+
                        "<p>When you've never lived in Malta before, it is best to rent first. This will allow you to travel around the country for your ideal location and enjoy life and the cost of living is Malta before making a final decision. Rent in Malta is frequently substantially lower than in other nations. This is especially true if you choose to live outside of the city core. </p>"+
                        "<h2>What Is the Definition of Commercial Real Estate? </h2>"+
                        "<p>In contrast to residential real estate, <b>commercial real estate</b> (CRE) is estate that is used purely for business reasons or to provide a workspace. Most commercial real estate is sold to tenants in order for them to generate income. This broad category of real estate might include anything from a single storefront to an entire shopping complex. Commercial real estate is divided into numerous areas, including office space, hotels & resorts, strip malls, cafés, and healthcare facilities. </p>"+
                        "<p>Finding the ideal commercial property might mean the difference between a successful organization and one that falls short of your objectives. Malta Properties has a large inventory of commercial properties available for lease. Browse our database to find retail outlets, stores, warehouses, shops, and offices. </p>"+
                        "<p>Renting Real Estate in Malta</p>"+
                        "<ul><li>Malta's seaside structures</li>"+
                        "<li>When you arrive in Malta, you look for a rental house. </li>"+
                        "<li>The majority of rentals are handled by a real estate agent. </li>"+
                        "<li>The majority of properties are rented completely furnished. </li></ul>"+
                        "<h2>Renting Office</h2>"+
                        "<p><b>Commercial rent Malta</b> is the most suitable subject for you to do business. They usually do this for the office here. We have a very established team of letting associates who deal mostly on Commercial property for rent in Malta and Office space for rent in Malta. Our office space database includes anything from affordable managed office space to offices suitable for small, medium, and large organizations. Air conditioning, segregated restrooms, gypsum walls, and sophisticated partitioning are all standard features today. There are several high end office spaces accessible that showcase cutting edge technology to make life easier for such Information Technology industry. If you are a foreign successful businessman relocating to Malta and want to rent or lease an office space, one of our representatives will gladly walk you through the entire procedure. </p>"
                      }}
                    ></div>
                  </div>
                )}

                {window.location.pathname === "/sales" && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={{ 
                        __html: "<h2>Property for Sale in Malta</h2>"+
                        "<p>Buying property in Malta is financially easy, but you have to meet certain conditions. These conditions are very important. You should carefully read and obey them. Let's look more closely at these circumstances. After this part, there are a few more important places you should know. We will tell them too. Property purchases and sales in Malta are governed by local regulations. Here are some points to be aware of before making your first investment here: </p>"+
                        "<ul><li>All contracts should be drafted in English. </li>"+
                        "<li>After signing the agreement, the buyer will deposit a payment equal to 10% of the purchase price with the Notary. </li>"+
                        "<li>If the buyer fails to complete the deed, the deposited sum will be returned to the seller, provided there is a sufficient legal justification for failure to finish. </li>"+
                        "<li>By default, the term of such a contract is three months, but parties might agree to a different validity period. </li>"+
                        "<li>When the buyer takes ownership of the property, they must pay customs duty and other legal fees. </li></ul>"+
                        "<h2>Purchasing Real Estate in Malta as a Foreigner</h2>"+
                        "<p>Malta, which consists of three islands, is located in the heart of the Mediterranean. There are various historic sites scattered around the islands, including carved stone templates and massive strongholds. Historic sites, and universe marina, a great climate, and a distinct culture make it an appealing destination for property investors. </p>"+
                        "<p>Malta is an excellent area to invest in real estate, particularly if you are an EU citizen. With new housing developments, it's no surprise that Malta continues to captivate property investors from around the world. In Malta, real estate is a valuable asset. A one bedroom apartment on the water can cost up to €200,000. </p>"+
                        "<p>Rental options in cosmopolitan but instead historic sections of the country will also thrill investors searching for rental income, thanks to a thriving tourism economy. Malta is a popular retirement location as well as a fantasy holiday destination. The government provides appealing retirement and residency schemes. Thousands of EU residents take up residence in Malta each year as a result of these schemes, contributing to the real estate industry's steady expansion. </p>"+
                        "<h2>What Should You Know Before Buying a Property in Malta? </h2>"+
                        "<p>Purchasing real estate in Malta is a sensible investment. Property in Malta appears to maintain long-term growth, thanks to demand from both domestic and international buyers. Those wishing to buy a home can select between ancient homes and new luxury apartments. Purchasing property within Malta is typically a simple process. Here's how a transaction goes down: </p>"+
                        "<ul><li>You locate and select a property to purchase. </li>"+
                        "<li>An architect may inspect the property's structure on your behalf. </li>"+
                        "<li>You and the vendor reach an agreement on the price and terms. Once agreed upon, both <li>parties will file an agreement to buy and sell real estate. </li>"+
                        "<li>Once signed, the commitment will be binding on the parties. </li>"+
                        "<li>A Notary Public will examine the title & prepare the final document of sale. It is important that this is so. It is of great importance in terms of protecting the rights of both parties. </li> </ul>"
                      }}
                    ></div>
                  </div>
                )}
                {props.category && props.category.mostPopularPropertyTypes && props.category.mostPopularPropertyTypes?.length > 0 && (
                  <MostPopularPropertyTypes mostPopularPropTypes={props.category.mostPopularPropertyTypes} />
                )}
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Ref>
    </Container>
  );
};

export default List;

const styles = (mapOpen) => ({
  container: {
    paddingTop: isMobile ? 10 : 30,
    paddingLeft: isMobile ? 0 : 20,
    paddingRight: isMobile ? 0 : 20,
  },
  listHeader: {
    margin: 0,
    padding: isMobile ? 0 : 5,
    backgroundColor: "#fff",
    borderRadius: 3,
    border: "1.5px solid #ccc",
    width: "100%",
  },
  items: {
    marginTop: isMobile ? 15 : 85,
  },
  iconButton: {
    backgroundColor: "#353a47",
    margin: 5
  },
  button: {
    backgroundColor: "#353a47",
    margin: 5
  },
  referenceNumber: {
    backgroundColor: "#fff"
  },
});
