import React from "react";
import { isMobile } from "react-device-detect";
import {
  Grid,
  Form,
  Dropdown,
  Button,
  Card,
  Radio,
  Checkbox,
} from "semantic-ui-react";
import { pageEnum } from "../../Common";
import { debounce } from "lodash";
import { getQueryParam } from "../../helpers/common";

const FilterForm = ({
  options,
  filter,
  forms,
  setFilterForm,
  setLettingType,
  pageNumber,
  sortingType,
  setFilterAccordion,
  category,
}) => {
  const prevPageNumber = usePrevious(pageNumber);
  const prevSortingType = usePrevious(sortingType);

  const [optionsData, setOptionsData] = React.useState({
    propertyTypes: [],
    areas: [],
    locations: [],
    features: [],
    rooms: [
      { key: 1, value: 1, text: "1" },
      { key: 2, value: 2, text: "2" },
      { key: 3, value: 3, text: "3" },
      { key: 4, value: 4, text: "4" },
      { key: 5, value: 5, text: "5" },
      { key: 6, value: 6, text: "6" },
      { key: 7, value: 7, text: "7" },
    ],
    availability: [
      { key: 0, value: 0, text: "Available Now" },
      { key: 1, value: 1, text: "Available Soon" },
      { key: 2, value: 2, text: "Available in Few Months" },
    ],
    lettingType: [
      { key: pageEnum.LongLets, text: "Long Lets", value: pageEnum.LongLets },
      {
        key: pageEnum.ShortLets,
        text: "Short Lets",
        value: pageEnum.ShortLets,
      },
      {
        key: pageEnum.CommercialLets,
        text: "Commercials",
        value: pageEnum.CommercialLets,
      },
      { key: pageEnum.ForSale, text: "Sale", value: pageEnum.ForSale },
      { key: pageEnum.Room, text: "Room", value: pageEnum.Room },
    ],
  });

  const [locationAllData, setLocationAllData] = React.useState([]);
  const url_propertyType = getQueryParam(
    "property-type",
    document.location.href
  );
  const url_location = getQueryParam("location", document.location.href);

  let initialFormData = {
    availability: null, //0: Now (Şu an Müsait), 1: Soon (Yakında Müsait Olacak)
    lettingType: forms?.lettingType ? forms.lettingType : pageNumber, //[1, 2, 3]
    propertyTypes: forms?.propertyTypes ? forms.propertyTypes : [], //[1, 2, 3]
    referanceNumber: null, //"TEST1234"
    minPrice: forms?.minPrice ? forms.minPrice : null, //300
    maxPrice: forms?.maxPrice ? forms.maxPrice : null, //1200
    rooms: [], //[1, 2, 3]
    bedrooms: [], //[1, 2, 3]
    bathrooms: [], //[1, 2, 3]
    areas: forms?.areas ? forms.areas : [], //[1, 2, 3]
    locations: forms?.locations ? forms.locations : [], //[1, 2, 3]
    features: [], //[1, 2, 3]
    sortingType: sortingType,
    isVideoExist: false,
  };
  if (category) {
    let catForm = category.form;
    initialFormData = {
      availability: null,
      lettingType: catForm.lettingType,
      propertyTypes: catForm.propertyTypes,
      referanceNumber: null,
      minPrice: catForm.minPrice ? catForm.minPrice : null,
      maxPrice: catForm?.maxPrice ? catForm.maxPrice : null,
      squareOfMetersMin: catForm.squareOfMetersMin,
      squareOfMetersMax: catForm.squareOfMetersMax,
      rooms: catForm.rooms,
      bedrooms: catForm.bedrooms,
      bathrooms: catForm.bathrooms,
      areas: catForm.areas,
      locations: catForm.locations,
      features: catForm.features,
      sortingType: sortingType,
      isVideoExist: false,
    };
  } else if (url_propertyType) {
    const ptS = url_propertyType.split("-");
    initialFormData.propertyTypes = [parseInt(ptS[ptS.length - 1])];
  } else if (url_location) {
    const lS = url_location.split("-");
    initialFormData.locations = [parseInt(lS[lS.length - 1])];
  }

  const [form, setForm] = React.useState(initialFormData);

  const [buttonsBasic, setButtonBasic] = React.useState({
    bd1: true,
    bd2: true,
    bd3: true,
    bd4: true,
    bt1: true,
    bt2: true,
    bt3: true,
    bt4: true,
  });
  const [isShowLocation, setIsShowLocation] = React.useState(false);

  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  //#region callback

  const handlerReferanceNumber = debounce((q) => submit(), 600);

  const handlerMinPrice = debounce((q) => submit(), 600);

  const handlerMaxPrice = debounce((q) => submit(), 600);

  const handlerMinMeter = debounce((q) => submit(), 600);

  const handlerMaxMeter = debounce((q) => submit(), 600);
  //#endregion

  //#region Mappers
  const mapPropertyTypes = (lettingType) => {
    let propTypes = [];
    options?.propertyTypes?.map((type) => {
      if (type.lettingType === lettingType) {
        propTypes.push({
          key: type.id.toString(),
          value: type.id,
          text: type.name,
        });
      }
    });
    return propTypes;
  };

  const mapAreas = () => {
    let areas = [];
    options?.areas?.map((type) => {
      areas.push({ key: type.id.toString(), value: type.id, text: type.name });
    });
    return areas;
  };

  const mapLocations = (areaIds) => {
    let locations = [];
    options?.locations?.map((type) => {
      if (areaIds.indexOf(type.groupId) > -1) {
        locations.push({
          key: type.id.toString(),
          value: type.id,
          text: type.name,
          groupid: type.groupId,
          isPopular: false,
        });
      }
    });
    // return locations

    for (let i = 0; i < form.locations.length; i++) {
      // const element = array[index];
      if (
        typeof locations.find((x) => x.key == form.locations[i]) === "undefined"
      ) {
        let index = locationAllData.findIndex(
          (x) => x.key == form.locations[i]
        );
        locations.unshift(locationAllData[index]);
      }
    }

    return locations;
  };

  const mapAllLocations = () => {
    let result1 = [],
      result2 = [];
    for (let i = 0; i < options?.locations?.length; i++) {
      let type = options.locations[i];
      if (options.locations[i].isPopular) {
        result1.push({
          key: type.id.toString(),
          value: type.id,
          text: type.name,
          groupid: type.groupId,
          isPopular: false,
        });
      } else {
        result2.push({
          key: type.id.toString(),
          value: type.id,
          text: type.name,
          groupid: type.groupId,
          isPopular: false,
        });
      }
    }

    let location = result1.concat(result2);

    for (let i = 0; i < form.locations.length; i++) {
      // const element = array[index];
      try {
        let index = location.findIndex((x) => x.key == form.locations[i]);
        location[index].isPopular = true;
      } catch (error) {
        console.log("mapp all element ekle " + error);
      }
    }

    return location;
    // options?.locations?.map(type => {
    //   locations.push({ key: type.id.toString(), value: type.id, text: type.name, groupid: type.groupId, isPopular: type.isPopular })
    // })
    // return locations
  };

  const mapFeatures = () => {
    let features = [];
    options?.features?.map((type) => {
      features.push({
        key: type.id.toString(),
        value: type.id,
        text: type.name,
      });
    });
    return features;
  };
  //#endregion

  React.useEffect(() => {
    if (typeof prevPageNumber === "undefined") {
      return;
    }
    if (typeof forms === "undefined") {
      if (!isMobile) {
        //indexde mobilde page number değişince istek atıyo zaten
        setForm({ ...form, lettingType: pageNumber });
      }
    }
  }, [pageNumber]);

  React.useEffect(() => {
    const propertyTypes = mapPropertyTypes(form.lettingType);
    const areas = mapAreas();
    // const locations = mapLocations([areas[0]?.value])
    const locations = mapAllLocations();
    const features = mapFeatures();
    setOptionsData({
      ...optionsData,
      propertyTypes,
      areas,
      locations,
      features,
    });
    setLocationAllData(locations);
  }, [options]);

  React.useEffect(() => {
    //lettingtype değişince property Types değişsin
    setLettingType(form.lettingType);
    const propertyTypes = mapPropertyTypes(form.lettingType);
    setOptionsData({
      ...optionsData,
      propertyTypes,
    });
  }, [form.lettingType]);

  React.useEffect(() => {
    setFilterForm(form);
    submit();
  }, [form]);

  React.useEffect(() => {
    if (typeof prevSortingType !== "undefined") {
      setForm({ ...form, sortingType: sortingType });
    }
  }, [sortingType]);

  // React.useEffect(() => {
  //   if (typeof forms !== 'undefined') {
  //     submit()
  //   }
  // }, [forms])

  const submit = () => {
    filter(form);
  };

  const resetAll = () => {
    document.getElementById("referanceNumber").value = "";
    document.getElementById("minPrice").value = "";
    document.getElementById("maxPrice").value = "";

    form.referanceNumber = null;
    form.minPrice = null;
    form.maxPrice = null;

    setButtonBasic({
      bd1: true,
      bd2: true,
      bd3: true,
      bd4: true,
      bt1: true,
      bt2: true,
      bt3: true,
      bt4: true,
    });

    setForm({
      availability: null,
      lettingType: pageNumber,
      propertyTypes: [],
      referanceNumber: null,
      minPrice: null, //300
      maxPrice: null, //1200
      squareOfMetersMin: null,
      squareOfMetersMax: null,
      rooms: [], //[1, 2, 3]
      bedrooms: [], //[1, 2, 3]
      bathrooms: [], //[1, 2, 3]
      areas: [], //[1, 2, 3]
      locations: [], //[1, 2, 3]
      features: [], //[1, 2, 3]
      isVideoExist: false,
    });
    setIsShowLocation(false);
    for (let i = 0; i < optionsData.locations.length; i++) {
      optionsData.locations[i].isPopular = false;
    }
  };

  function triggerInput(enteredName, enteredValue) {
    const input = document.getElementById(enteredName);

    const lastValue = input.value;

    input.value = enteredValue;

    const event = new Event("input", { bubbles: true });

    const tracker = input._valueTracker;

    if (tracker) {
      tracker.setValue(lastValue);
    }

    input.dispatchEvent(event);
  }

  return (
    <div style={styles.container}>
      <Grid>
        <Grid.Column width="8">
          <span style={styles.title}>Filter</span>
        </Grid.Column>
        <Grid.Column width="8" style={{ textAlign: "right" }}>
          <a onClick={resetAll}>Reset All</a>
        </Grid.Column>
      </Grid>
      <Form style={styles.form}>
        <Form.Field>Availability</Form.Field>
        <Form.Field>
          <Radio
            label="Available Now"
            name="radioGroup"
            value={0}
            checked={form.availability === 0}
            onChange={() => setForm({ ...form, availability: 0 })}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Available Soon"
            name="radioGroup"
            value={1}
            checked={form.availability === 1}
            onChange={() => setForm({ ...form, availability: 1 })}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Available in Few Months"
            name="radioGroup"
            value={2}
            checked={form.availability === 2}
            onChange={() => setForm({ ...form, availability: 2 })}
          />
        </Form.Field>

        <Form.Field required>
          <Dropdown
            placeholder="Choose Letting Type"
            fluid
            selection
            options={optionsData.lettingType}
            value={form.lettingType}
            onChange={(e, d) => {
              setForm({
                ...form,
                lettingType: d.value,
              });
            }}
          />
        </Form.Field>

        <Form.Field>
          <Dropdown
            fluid
            text="Property Type"
            selection
            multiple
            className="easy-multiple-dropdown"
            options={optionsData.propertyTypes}
            value={form.propertyTypes}
            onChange={(e, { value }) =>
              setForm({ ...form, propertyTypes: value })
            }
          />
        </Form.Field>
        <Form.Field>
          <input
            type="number"
            name="referanceNumber"
            id="referanceNumber"
            placeholder="Referance Number"
            onChange={(e) => {
              form.referanceNumber = e.currentTarget.value;
              handlerReferanceNumber(e.currentTarget.value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Grid style={{ margin: 0, padding: 0, width: "100%" }}>
            <Grid.Column width="7" style={{ padding: 0 }}>
              <input
                name="minPrice"
                id="minPrice"
                placeholder="Min. Price"
                type="number"
                onChange={(e) => {
                  form.minPrice = e.currentTarget.value;
                  handlerMinPrice(e.currentTarget.value);
                }}
              />
            </Grid.Column>
            <Grid.Column width="7" floated="right" style={{ padding: 0 }}>
              <input
                name="maxPrice"
                id="maxPrice"
                placeholder="Max. Price"
                type="number"
                onChange={(e) => {
                  form.maxPrice = e.currentTarget.value;
                  handlerMaxPrice(e.currentTarget.value);
                }}
              />
            </Grid.Column>
          </Grid>
        </Form.Field>
        <Form.Field>
          <Grid style={{ margin: 0, padding: 0, width: "100%" }}>
            <Grid.Column width="7" style={{ padding: 0 }}>
              <input
                name="minMeter"
                id="minMeter"
                placeholder="Min. Meter"
                type="number"
                value={
                  form.squareOfMetersMin === 0 ? "" : form.squareOfMetersMin
                }
                onChange={(e) => {
                  form.squareOfMetersMin = e.currentTarget.value;
                  handlerMinMeter(e.currentTarget.value);
                }}
              />
            </Grid.Column>
            <Grid.Column width="7" floated="right" style={{ padding: 0 }}>
              <input
                name="maxMeter"
                id="maxMeter"
                placeholder="Max. Meter"
                type="number"
                value={
                  form.squareOfMetersMax === 0 ? "" : form.squareOfMetersMax
                }
                onChange={(e) => {
                  form.squareOfMetersMax = e.currentTarget.value;
                  handlerMaxMeter(e.currentTarget.value);
                }}
              />
            </Grid.Column>
          </Grid>
        </Form.Field>

        <Form.Field>
          <Checkbox
            checked={form.isVideoExist}
            onChange={(e, d) => {
              setForm({ ...form, isVideoExist: d.checked });
            }}
            label="Ads with Video"
          />
        </Form.Field>

        <Form.Field>
          <Grid.Column width="8">
            {/* <Button color="green" basic={true} >Bedrooms</Button> */}
            <Card.Content>Bedrooms</Card.Content>
          </Grid.Column>
          <Grid style={{ width: "100%", marginTop: "1px" }}>
            <Grid.Column width="4">
              <Button
                color="green"
                size="tiny"
                basic={buttonsBasic.bd1}
                style={{ "white-space": "nowrap" }}
                onClick={() => {
                  if (buttonsBasic.bd1) {
                    setForm({ ...form, bedrooms: [...form.bedrooms, 1] });
                  } else {
                    setForm({
                      ...form,
                      bedrooms: form.bedrooms.filter((x) => x !== 1),
                    });
                  }
                  setButtonBasic({ ...buttonsBasic, bd1: !buttonsBasic.bd1 });
                }}
              >
                1
              </Button>
            </Grid.Column>
            <Grid.Column width="4">
              <Button
                color="green"
                size="tiny"
                basic={buttonsBasic.bd2}
                style={{ "white-space": "nowrap" }}
                onClick={() => {
                  if (buttonsBasic.bd2) {
                    setForm({ ...form, bedrooms: [...form.bedrooms, 2] });
                  } else {
                    setForm({
                      ...form,
                      bedrooms: form.bedrooms.filter((x) => x !== 2),
                    });
                  }
                  setButtonBasic({ ...buttonsBasic, bd2: !buttonsBasic.bd2 });
                }}
              >
                2{" "}
              </Button>
            </Grid.Column>
            <Grid.Column width="4">
              <Button
                color="green"
                size="tiny"
                basic={buttonsBasic.bd3}
                style={{ "white-space": "nowrap" }}
                onClick={() => {
                  if (buttonsBasic.bd3) {
                    setForm({ ...form, bedrooms: [...form.bedrooms, 3] });
                  } else {
                    setForm({
                      ...form,
                      bedrooms: form.bedrooms.filter((x) => x !== 3),
                    });
                  }
                  setButtonBasic({ ...buttonsBasic, bd3: !buttonsBasic.bd3 });
                }}
              >
                3
              </Button>
            </Grid.Column>
            <Grid.Column width="4">
              <Button
                color="green"
                size="tiny"
                basic={buttonsBasic.bd4}
                style={{ "white-space": "nowrap" }}
                onClick={() => {
                  if (buttonsBasic.bd4) {
                    setForm({
                      ...form,
                      bedrooms: [...form.bedrooms, 4, 5, 6, 7, 8],
                    });
                  } else {
                    setForm({
                      ...form,
                      bedrooms: form.bedrooms.filter((x) => x < 4),
                    });
                  }
                  setButtonBasic({ ...buttonsBasic, bd4: !buttonsBasic.bd4 });
                }}
              >
                +4
              </Button>
            </Grid.Column>
          </Grid>
        </Form.Field>

        <Form.Field>
          <Grid.Column width="8">
            {/* <Button color="green" basic={true} >Bathrooms</Button> */}
            <Card.Content>Bathrooms</Card.Content>
          </Grid.Column>
          <Grid style={{ width: "100%", marginTop: "1px" }}>
            <Grid.Column width="4">
              <Button
                color="green"
                size="tiny"
                basic={buttonsBasic.bt1}
                style={{ "white-space": "nowrap" }}
                onClick={() => {
                  if (buttonsBasic.bt1) {
                    setForm({ ...form, bathrooms: [...form.bathrooms, 1] });
                  } else {
                    setForm({
                      ...form,
                      bathrooms: form.bathrooms.filter((x) => x !== 1),
                    });
                  }
                  setButtonBasic({ ...buttonsBasic, bt1: !buttonsBasic.bt1 });
                }}
              >
                1
              </Button>
            </Grid.Column>
            <Grid.Column width="4">
              <Button
                color="green"
                size="tiny"
                basic={buttonsBasic.bt2}
                style={{ "white-space": "nowrap" }}
                onClick={() => {
                  if (buttonsBasic.bt2) {
                    setForm({ ...form, bathrooms: [...form.bathrooms, 2] });
                  } else {
                    setForm({
                      ...form,
                      bathrooms: form.bathrooms.filter((x) => x !== 2),
                    });
                  }
                  setButtonBasic({ ...buttonsBasic, bt2: !buttonsBasic.bt2 });
                }}
              >
                2
              </Button>
            </Grid.Column>
            <Grid.Column width="4">
              <Button
                color="green"
                size="tiny"
                basic={buttonsBasic.bt3}
                style={{ "white-space": "nowrap" }}
                onClick={() => {
                  if (buttonsBasic.bt3) {
                    setForm({ ...form, bathrooms: [...form.bathrooms, 3] });
                  } else {
                    setForm({
                      ...form,
                      bathrooms: form.bathrooms.filter((x) => x !== 3),
                    });
                  }
                  setButtonBasic({ ...buttonsBasic, bt3: !buttonsBasic.bt3 });
                }}
              >
                3
              </Button>
            </Grid.Column>
            <Grid.Column width="4">
              <Button
                color="green"
                size="tiny"
                basic={buttonsBasic.bt4}
                style={{ "white-space": "nowrap" }}
                onClick={() => {
                  if (buttonsBasic.bt4) {
                    setForm({
                      ...form,
                      bathrooms: [...form.bathrooms, 4, 5, 6, 7, 8],
                    });
                  } else {
                    setForm({
                      ...form,
                      bathrooms: form.bathrooms.filter((x) => x < 4),
                    });
                  }
                  setButtonBasic({ ...buttonsBasic, bt4: !buttonsBasic.bt4 });
                }}
              >
                +4
              </Button>
            </Grid.Column>
          </Grid>
        </Form.Field>

        <Form.Field>
          <Dropdown
            fluid
            text="Area"
            selection
            multiple
            options={optionsData.areas}
            search
            value={form.areas}
            className="easy-multiple-dropdown"
            onChange={(e, { value }) => {
              if (value.length === 0) {
                setIsShowLocation(false);
                const locations = mapAllLocations();
                setOptionsData({
                  ...optionsData,
                  locations,
                });
                setLocationAllData(locations);
                setForm({ ...form, areas: value });
                return;
              }
              const locations = mapLocations(value);
              setOptionsData({ ...optionsData, locations });
              setLocationAllData(locations);
              setForm({ ...form, areas: value });
              /*
                Area seçimi güncellendi. Locations içerisindeki veriler Area'lara bağlı (İl-İlçe)
                Bağlı olduğu Area seçili değil ise; Location seçilemez. Seçilmiş ise kaldırılması gerekir.
                Kaldırılması gereken seçilmiş Location'ları bul ve seçimlerini kaldır.
              */
            }}
          ></Dropdown>
        </Form.Field>
        <Form.Field>
          {/* <Dropdown
            fluid
            text='Locations' selection multiple options={optionsData.locations} search
            value={form.locations}
            onChange={(e, { value }) => setForm({ ...form, locations: value })}
            className="easy-multiple-dropdown"
          >
          </Dropdown> */}
          Quick Find Location
        </Form.Field>
        <Form.Field>
          <input
            placeholder="Locations.."
            name="location"
            id="location"
            onChange={(e) => {
              let inputSize = e.currentTarget.value.length;
              if (inputSize === 0) {
                setIsShowLocation(false);
              } else {
                setIsShowLocation(true);
              }
              const newData = locationAllData.filter((item) => {
                const listItem = `${item.text?.toLowerCase()}`;
                return (
                  listItem.indexOf(e.currentTarget.value.toLocaleLowerCase()) >
                  -1
                );
              });
              setOptionsData({ ...optionsData, locations: newData });
            }}
          />
        </Form.Field>
        <Form.Field>
          <Grid>
            {optionsData.locations
              .slice(0, isShowLocation ? optionsData.locations.length : 20)
              .map((item) => (
                <Grid.Column width="8">
                  <Checkbox
                    label={item.text}
                    checked={item.isPopular}
                    onChange={(e, d) => {
                      let index = optionsData.locations.findIndex(
                        (x) => x.value === item.value
                      );
                      switch (d.checked) {
                        case true:
                          // setOptionsData({...optionsData,locations})
                          optionsData.locations[index].isPopular = true;
                          setForm({
                            ...form,
                            locations: [...form.locations, item.value],
                          });
                          break;
                        default:
                          optionsData.locations[index].isPopular = false;
                          setForm({
                            ...form,
                            locations: form.locations.filter(
                              (x) => x !== item.value
                            ),
                          });
                          break;
                      }
                      // document.getElementById('location').value = ""
                      triggerInput("location", "");
                    }}
                  />
                </Grid.Column>
              ))}

            {optionsData.locations.length < 20 ? null : !isShowLocation ? (
              <Form.Field width="16" style={{ padding: 10 }}>
                <Button
                  fluid
                  color="green"
                  basic={true}
                  onClick={() => setIsShowLocation(true)}
                >
                  Show More
                </Button>
              </Form.Field>
            ) : (
              <Button
                fluid
                color="green"
                basic={true}
                onClick={() => setIsShowLocation(false)}
              >
                Show Less
              </Button>
            )}
          </Grid>
        </Form.Field>

        <Form.Field>
          <Dropdown
            fluid
            text="Features"
            selection
            multiple
            search
            options={optionsData.features}
            value={form.features}
            onChange={(e, { value }) => setForm({ ...form, features: value })}
            className="easy-multiple-dropdown"
          ></Dropdown>
        </Form.Field>

        {isMobile ? (
          <Button
            fluid
            color="red"
            basic={true}
            onClick={() => {
              window.scrollTo(0, 0);
              setFilterAccordion();
            }}
          >
            Close
          </Button>
        ) : null}
        {/* <Button color="green" fluid onClick={() => submit()}>Submit</Button> */}
      </Form>
    </div>
  );
};

export default FilterForm;

const styles = {
  container: {
    // width: 300,
    backgroundColor: "#fff",
    padding: "10%",
    borderRadius: 3,
    border: "1.5px solid #ccc",
  },
  title: {
    fontSize: "x-large",
  },
  form: {
    marginTop: 15,
  },
};
