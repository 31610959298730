import { pageEnum } from "../Common";

export const getListingUrl = (type) => {
  switch (type) {
    case pageEnum.LongLets:
      return "/long-lets";
    case pageEnum.CommercialLets:
      return "/commercials";
    case pageEnum.ShortLets:
      return "/short-lets";
    case pageEnum.ForSale:
      return "/sales";
    case pageEnum.Room:
      return "/room";
    default:
      return "#";
  }
};

export const getListingName = (type) => {
  switch (type) {
    case pageEnum.LongLets:
      return "Malta Long Let Properties";
    case pageEnum.CommercialLets:
      return "Commercial Properties in Malta";
    case pageEnum.ShortLets:
      return "Short Let Properties";
    case pageEnum.ForSale:
      return "Property For Sale in Malta";
    case pageEnum.Room:
      return "Rooms in Malta";
    default:
      return "";
  }
};

export const getQueryParam = (name, url) => {
  if (!url) url = document.location.href;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  return results == null ? null : results[1];
};
