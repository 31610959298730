import React, { useState } from 'react'
import { Grid, Message, Container, Icon, Breadcrumb, Form, Accordion } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha"
import api from '../../api'
import { setRefId, getRefId } from '../../helpers/cookie'

import { Helmet } from 'react-helmet'

const Career = () => {

  const [activeIndex, setActiveIndex] = React.useState(-1)
  const [data, setData] = React.useState([])
  const [form, setForm] = React.useState({
    Name: '', Email: '', Phone: '', Comment: '', Captcha: '', File: undefined
  })
  const [captch, setCaptch] = useState(true)
  const [cv, setCv] = useState(false)
  const [isSuccess, setIsSuccess] = React.useState(0)//ilk hali 0, başarılı 1, başarısız 2

  React.useEffect(() => {

    const script = document.createElement("script")
    script.async = true

    script.type = "application/ld+json"

    const codeStr = `
      "@context": "https://schema.org",
      "@type": "RealEstateAgent",
      "name": "Easy Rent",
      "image": "https://www.easyrent.com.mt/img/logo-green.png",
      "@id": "",
      "url": "https://www.easyrent.com.mt/",
      "telephone": "99998330",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "420 Triq D'Argens",
        "addressLocality": "Gżira",
        "postalCode": "",
        "addressCountry": "MT"
      } ,
      "sameAs": [
        "https://www.facebook.com/easyrentmt/",
        "https://www.instagram.com/easyrent.malta/",
        "https://www.youtube.com/channel/UCjo81yeSg4l4JJ3qkl79Hfw",
        "https://www.linkedin.com/company/easyrent-malta/"
      ] 
  `
    script.innerHTML = codeStr

    document.body.appendChild(script);

    let url = window.location.href
    let ref = url.split('?ref=')

    if (ref.length > 1) {
      setRefId(ref[1])
    }

    const load = async () => {
      const res = await api.careers.getCareers()
      if (res?.result) {
        setData(res.result)
      }
    }

    load()
  }, [])

  const messagePositive = () => (
    <Message positive>
      <Message.Header>Successful</Message.Header>
      <p>
        Your request has been sent successfully
      </p>
    </Message>
  )

  const messageNegative = () => (
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>An unexpected error has occurred. Please try again.</p>
    </Message>
  )

  const messageCV = () => (
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>Please choose CV. Please try again.</p>
    </Message>
  )

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex)
  }

  const sendNow = async () => {

    if (form.File === undefined) {
      setCv(true)
      return
    }

    if (form.Name === '' || form.Email === '' || form.Phone === '') {
      alert('(*) fields are required.')
      return
    }

    let formData = new FormData()
    formData.append('Name', form.Name)
    formData.append('Email', form.Email)
    formData.append('Phone', form.Phone)
    formData.append('Comment', form.Comment)
    formData.append('Captcha', form.Captcha)
    formData.append('File', form.File)
    formData.append('RefId', getRefId() === null ? 0 : getRefId())

    const res = await api.careers.postCareers(formData)
    if (res.statusCode === 200) {
      setIsSuccess(1)
      setForm({
        Name: '', Email: '', Phone: '', Comment: '', Captcha: '', File: undefined
      })
      document.getElementById('Name').value = ""
      document.getElementById('Email').value = ""
      document.getElementById('Phone').value = ""
      document.getElementById('CV').value = ""
      document.getElementById('Comment').value = ""
      window.location.reload(false)
    } else {
      setIsSuccess(2)
    }
  }

  const onChange = (value) => {
    console.log("Captcha value:", value);
    setForm({ ...form, Captcha: value })
    if (value !== null) {
      setCaptch(false)
    } else {
      setCaptch(true)
    }
  }

  const renderAccordions = () => (
    <Accordion styled fluid>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
      >
        <Icon name='dropdown' />
        {data?.map(item =>
          item.title
        )}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        {data?.map(item =>
          <div key={item.id} dangerouslySetInnerHTML={{ __html: item.description }} />
        )}
      </Accordion.Content>
    </Accordion>
  )

  return (
    <Container style={{ paddingTop: '2rem' }}>

      <Helmet>
        <title>Property to Rent Malta | Real Estate Agent in Malta</title>
        <meta name="description" content="Long Lets and Short Lets Apartments in Malta. Easy Rent Malta – One of the best Real Estate agent in Malta. The easiest way to find your ideal property in Malta." />
        <meta name="keywords" content="apartment for rent, rent in malta, real estate in malta, accommodation in malta, long lets apartments, short lets apartments, commercial property, property for sale" />

      </Helmet>

      <Breadcrumb>
        <Breadcrumb.Section link href="/">Home</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Careers</Breadcrumb.Section>
      </Breadcrumb>

      <Grid style={{ paddingTop: '5rem', paddingBottom: '10rem' }}>
        <Grid.Column computer={10} mobile={16}>
          <h1>CAREERS</h1>
          <p>
            Our growth is down to our practical, innovative and professional people delivering trusted advice and support to our clients. Join us and become part of EasyRent entrepreneurial culture, where innovation, inclusion and commitment are rewarded and where every individual is respected
          </p>
          {
            isSuccess === 1 ? messagePositive() : isSuccess === 2 ? messageNegative() : null
          }
          <h4>Open Positions</h4>
          {renderAccordions()}
          <Form style={{ marginTop: 30 }}>
            <Form.Group widths='equal'>
              <Form.Input fluid label='Name' id='Name' placeholder='Name' onChange={e => setForm({ ...form, Name: e.currentTarget.value })} />
              <Form.Input fluid label='Email' id='Email' placeholder='Email' onChange={e => setForm({ ...form, Email: e.currentTarget.value })} />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input fluid label='Phone' id='Phone' placeholder='Phone' onChange={e => setForm({ ...form, Phone: e.currentTarget.value })} />
              <Form.Input fluid label='CV'>
                <input type="file" id='CV' onChange={e => setForm({ ...form, ...{ File: e.target.files[0] } })} />
              </Form.Input>
            </Form.Group>
            <Form.TextArea label='Comment' id='Comment' placeholder='Tell us more about you...' onChange={e => setForm({ ...form, Comment: e.currentTarget.value })} />

            <ReCAPTCHA
              sitekey="6LdZIDIaAAAAAMmQ0DWPLAlsyPjBtJLk1wc2-4Vo"
              onChange={onChange}
            />
            {
              cv ? messageCV() : null
            }

            <Form.Button disabled={captch} color="green" onClick={sendNow}>SEND NOW</Form.Button>
          </Form>

        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <h1>ADDRESS</h1>
          <br />
          <Icon name="map pin" /> 420 Triq D'Argens, Il-Gżira
          <br /><br />
          <Icon name="envelope open" /> <a href="mailto:info@easyrent.com.mt">info@easyrent.com.mt</a>
          <br /><br />
          <Icon name="phone" /> <a href="tel:99998330">99998330</a>
          <br /><br />
          <a href="https://www.facebook.com/easyrentmt/" target="_blank"><Icon name="facebook" /></a>
          <a href="https://www.instagram.com/easyrent.malta" target="_blank"><Icon name="instagram" /></a>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default Career
