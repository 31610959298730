import React from 'react'
import { Button } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'

const openInNewTab = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

const ButtonsBar = ({
  openContactAgentModal,
  openRequestViewingModal,
  openShowLocationModal,
  referenceNumber,
  openVideoModal,
  lettingType,
  url = null
}) => {
  return (
    <div>
      {/* <Button style={styles.iconButton} icon={"tasks"} color="brown" />
      <Button style={styles.iconButton} icon={"heart outline"} color="brown" />
      <Button style={styles.iconButton} icon={"share alternate"} color="brown" /> */}

      <Button style={styles.button} color="brown" content={"Show Location"} onClick={() => openShowLocationModal()} />
      <Button style={styles.button} color="brown" content={"Request a Viewing"} onClick={() => openRequestViewingModal()} />
      <Button style={styles.button} color="brown" content={"Contact Agent"} onClick={() => openContactAgentModal()} />
      {
        lettingType === "5" ?
        <Button style={styles.button} color ="green" content={"Find Flat Mate"} onClick={() => openInNewTab('./flatmate')} />
          : null
      }

      {
        url !== null ?
          <Button style={styles.button} color="brown" content={"Show Video"} onClick={() => openVideoModal()} />
          : null
      }
      {
        !isMobile ? <Button style={styles.referenceNumber} color="white" content={'Reference No: ' + referenceNumber} onClick={() => openContactAgentModal()} />
          : null
      }

    </div>
  )
}

export default ButtonsBar

const styles = {
  iconButton: {
    backgroundColor: "#353a47",
    margin: 5
  },
  button: {
    backgroundColor: "#353a47",
    margin: 5
  },
  referenceNumber: {
    backgroundColor: "#fff"
  }
}
