import axios from "axios";

const API_URL_TEST = "https://qa-webapi.easyrent.com.mt";
const API_URL_LIVE = "https://webapi.easyrent.com.mt";
let API_URL = "";
const host = document.location.host;

if (
  host.toLowerCase().startsWith("qa-web.") ||
  host.toLowerCase().startsWith("localhost")
) {
  API_URL = API_URL_TEST;
} else if (host.toLowerCase().startsWith("easyrent")) {
  API_URL = API_URL_LIVE;
} else {
  API_URL = API_URL_LIVE;
}

let caller = axios.create({
  baseURL: API_URL,
});

export default caller;
