import React from 'react'
import { Link } from 'react-router-dom'
import { Image, Grid, Container, List, Form, Icon } from 'semantic-ui-react'

function Footer() {
  return (
    <div className="easyfooter-container">
      <Container fluid style={styles.container} className="easyfooter">

        <div style={styles.footerLogo}>
          <Image alt="logo-white" src="/img/logo-white.png" style={{ width: '181px', height: '60px' }} />
          <div style={{ flexDirection: "row" }}>
            <a href="https://www.facebook.com/easyrentmt/"
              rel="noopener" target="_blank" style={{ paddingRight: '8px' }}
            ><strong style={{ color: 'black' }}>'</strong><Icon name="facebook" /></a>
            <Icon name="google" />
           
            <a href="https://youtube.com/channel/UCjo81yeSg4l4JJ3qkl79Hfw"
              rel="noopener" target="_blank" style={{ paddingRight: '8px' }}
            ><strong style={{ color: 'black' }}
            >'</strong><Icon name="youtube" /></a>
           
            <a href="https://www.instagram.com/easyrent.malta"
              rel="noopener" target="_blank"  style={{ paddingRight: '8px'}}
            ><strong style={{ color: 'black' }}>'</strong><Icon name="instagram" /></a>
           
            <Icon name="twitter"  style={{ paddingRight: '8px' }} />
            <Icon name="facebook"/>
          </div>
        </div>

        <Grid container>
          <Grid.Column computer={"4"} mobile="16">
            <h3>Properties</h3>
            <List>
              <List.Item><Link to="/luxury-villas-for-rent-in-malta">Luxury Villas For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/townhouses-for-rent-in-malta">Townhouses For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/penthouses-for-rent-in-malta">Penthouses For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/maisonettes-for-rent-in-malta">Maisonettes For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/apartments-for-rent-in-malta">Apartments For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/farmhouses-for-rent-in-malta">Farmhouses For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/terraced-houses-for-rent-in-malta">Terraced Houses For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/houses-of-character-for-rent-in-malta">Houses of Character For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/seafront-property-for-rent-in-malta">Seafront Property For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/bars-for-rent-in-malta">Bars for Rent in Malta</Link></List.Item>
              <List.Item><Link to="/offices-for-rent-in-malta">Offices for Rent in Malta</Link></List.Item>
              <List.Item><Link to="/bungalows-for-rent-in-malta">Bungalows For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/boathouse-for-rent-in-malta">Boathouse For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/garage-for-rent-in-malta">Garage For Rent in Malta</Link></List.Item>
              <List.Item><Link to="/hotels-for-rent-in-malta">Hotels For Rent in Malta</Link></List.Item>
            </List>
          </Grid.Column>
          <Grid.Column computer={"4"} mobile="16">
            <h3>Locations</h3>
            <List>
              <List.Item><Link to="/property-for-rent-in-sliema">Property For Rent in Sliema</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-valletta">Property For Rent in Valletta</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-swieqi">Property For Rent in Swieqi</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-naxxar">Property For Rent in Naxxar</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-ta-xbiex">Property For Rent in Ta'Xbiex</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-san-gwann">Property For Rent in San Gwann</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-rabat">Property For Rent in Rabat</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-madliena">Property For Rent in Madliena</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-st-pauls-bay">Property For Rent in St. Paul's Bay</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-st-julians">Property For Rent in St. Julian's</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-mellieha">Property For Rent in Mellieha</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-gozo">Property For Rent in Gozo</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-cental-malta">Property For Rent in Central, Malta</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-south-malta">Property For Rent in South, Malta</Link></List.Item>
              <List.Item><Link to="/property-for-rent-in-north-malta">Property For Rent in North, Malta</Link></List.Item>
            </List>
          </Grid.Column>
          <Grid.Column computer={"3"} mobile="16">
            <h3>About Us</h3>
            <List>
              <List.Item><Link to="/">Home</Link></List.Item>
              <List.Item><Link to="/company">Company</Link></List.Item>
              <List.Item><Link to="/career">Careers</Link></List.Item>
              <List.Item><Link to="/contact">Contact Us</Link></List.Item>
            </List>
            <h3>Quick Links</h3>
            <List>
              <List.Item><Link to="/short-lets" pageNumber="1" >Short Let Properties</Link></List.Item>
              <List.Item><Link to="/long-lets">Long Let Properties</Link></List.Item>
              <List.Item><Link to="/commercials">Commercial Properties</Link></List.Item>
            </List>
            <h3>Contact</h3>
            <List>
              <List.Item>
                <List.Icon name='marker' />
                <List.Content>420 Triq D'Argens, Il-Gżira</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='phone' />
                <List.Content><a href="tel:99998330">99998330</a></List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name='mail' />
                <List.Content><a href="mailto:info@easyrent.com.mt">info@easyrent.com.mt</a>
                </List.Content>
              </List.Item>
              
            </List>
          </Grid.Column>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Grid.Column computer={"3"} mobile="16">
              <h3>Newsletter</h3>
              <List>
                <List.Item>Sign up and we'll send the best deals to you.</List.Item>
              </List>
              <Form>
                <Form.Group>
                  <Form.Input
                    placeholder='Email'
                    name='name'
                  />
                  <Form.Button color="green" content='Register' />
                </Form.Group>
              </Form>
            </Grid.Column>
          </div>

        </Grid>
      </Container>
    </div>
  )
}

export default Footer

const styles = {
  container: {

  }, footerLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    flexDirection: "column",
    flex: 1
  }

}