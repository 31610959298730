import React from "react";
import api from "../../api";
import Map from "./Maps";
import List from "./List";
import "./styles.scss";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { pageEnum } from "../../Common";
import { setRefId } from "../../helpers/cookie";

const PropertyListing = ({ pageNumber, category }) => {
  const location = useLocation();
  const [mapVisible, setMapVisible] = React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState(null);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [properties, setProperties] = React.useState([]);
  const [propertiesLoading, setPropertiesLoading] = React.useState(true);
  const [filterForm, setFilterForm] = React.useState({});
  const [mapCenter, setMapCenter] = React.useState({});

  const prevPageNumber = usePrevious(pageNumber);

  // const test = (mapVisible && !propertiesLoading)//true false döner

  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const getProperties = async (page = pageIndex, filter) => {
    let lettingType =
      typeof filter === "undefined" ? pageNumber : filter.lettingType;
    if (category != null) {
      lettingType = category.lettingType;
    }
    setPropertiesLoading(true);
    const res = await api.properties.getProperties(page, filter, lettingType);
    setProperties(res?.result);

    const centerMapData = res?.result.data.filter((x) => x.latitude);
    if (centerMapData.length > 0) {
      let averageLat =
        centerMapData.map((x) => x.latitude).reduce((a, b) => a + b, 0) /
        centerMapData.length;
      let averageLng =
        centerMapData.map((x) => x.longitude).reduce((a, b) => a + b, 0) /
        centerMapData.length;
      setMapCenter({ latitude: averageLat, longitude: averageLng });
    }

    setPageIndex(page + 1);
    setPropertiesLoading(false);
  };

  React.useEffect(() => {
    const script = document.createElement("script");
    script.async = true;

    script.type = "application/ld+json";

    const codeStr = `
      "@context": "https://schema.org",
      "@type": "RealEstateAgent",
      "name": "Easy Rent",
      "image": "https://www.easyrent.com.mt/img/logo-green.png",
      "@id": "",
      "url": "https://www.easyrent.com.mt/",
      "telephone": "99998330",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "420 Triq D'Argens",
        "addressLocality": "Gżira",
        "postalCode": "",
        "addressCountry": "MT"
      } ,
      "sameAs": [
        "https://www.facebook.com/easyrentmt/",
        "https://www.instagram.com/easyrent.malta/",
        "https://www.youtube.com/channel/UCjo81yeSg4l4JJ3qkl79Hfw",
        "https://www.linkedin.com/company/easyrent-malta/"
      ] 
  `;
    script.innerHTML = codeStr;

    document.body.appendChild(script);

    let url = window.location.href;
    let ref = url.split("?ref=");

    if (ref.length > 1) {
      setRefId(ref[1]);
    }

    const load = async () => {
      const options = await api.properties.getFilterOptions();
      if (options?.result /* options.statusCode == 200 */) {
        setFilterOptions(options?.result);
      }
    };
    load();
  }, []);

  React.useEffect(() => {
    if (isMobile) {
      if (typeof prevPageNumber !== "undefined") {
        getProperties(0, { lettingType: pageNumber }, pageNumber);
      }
    }
  }, [pageNumber]);

  function getTitleMeta() {
    if (category) {
      return (
        <Helmet>
          <title property="title">{category.name} | Easy Rent Malta</title>
          <meta
            property="description"
            name="description"
            content={category.metaDescription}
          />
          <meta
            name="keywords"
            content="long let property, long let property malta, long let apartments malta"
          />
          <meta
            property="og:image"
            content="https://www.easyrent.com.mt/img/logo-green.png"
          ></meta>
        </Helmet>
      );
    }
    switch (pageNumber) {
      case pageEnum.LongLets:
        return (
          <Helmet>
            <title property="title">
              Long Let Property - Long Let Apartments in Malta | Easy Rent Malta
            </title>
            <meta
              property="description"
              name="description"
              content="Looking for Long Let Property or Long Let Apartments in Malta? Easy Rent Malta - The easiest way to find your ideal Long Let Property or Apartments in Malta."
            />
            <meta
              name="keywords"
              content="long let property, long let property malta, long let apartments malta"
            />
            <meta
              property="og:image"
              content="https://www.easyrent.com.mt/img/logo-green.png"
            ></meta>
          </Helmet>
        );
      case pageEnum.ShortLets:
        return (
          <Helmet>
            <title property="title">
              Short Let Property - Short Let Apartments in Malta | Easy Rent
              Malta
            </title>
            <meta
              property="description"
              name="description"
              content="Looking for Short Let Property or Short Let Apartments in Malta? Easy Rent Malta - The easiest way to find your ideal Short Let Property or Apartments in Malta."
            />
            <meta
              name="keywords"
              content="short let property, short let property malta, short let apartments malta"
            />
            <meta
              property="og:image"
              content="https://www.easyrent.com.mt/img/logo-green.png"
            ></meta>
          </Helmet>
        );

      case pageEnum.CommercialLets:
        return (
          <Helmet>
            <title property="title">
              Commercial Property (Shops, Restaurant, Office) for Rent in Malta
              | Easy Rent Malta
            </title>
            <meta
              property="description"
              name="description"
              content="Looking for Commercial Property (Shops, Restaurant, Office and Garage) for Rent in Malta? Easy Rent Malta - The easiest way to find your ideal Commercial Property in Malta"
            />
            <meta
              name="keywords"
              content="commercial property in malta, commercial property rent malta, commercial property for rent in malta"
            />
            <meta
              property="og:image"
              content="https://www.easyrent.com.mt/img/logo-green.png"
            ></meta>
          </Helmet>
        );

      case pageEnum.ForSale:
        return (
          <Helmet>
            <title property="title">
              Property For Sale in Malta | Easy Rent Real Estate Agent in Malta
            </title>
            <meta
              property="description"
              name="description"
              content="Properties for sale - Buy Apartment, Villa, Penthouse, Duplex Apartment in Malta. Easy Rent Malta - The easiest way to find your ideal Apartment, Villa, Penthouse, Duplex Apartment in Malta."
            />
            <meta
              name="keywords"
              content="apartment for sale in malta, villa for sale in malta, penthouse for sale in malta, duplex apartment sale in malta"
            />
            <meta
              property="og:image"
              content="https://www.easyrent.com.mt/img/logo-green.png"
            ></meta>
          </Helmet>
        );
      case pageEnum.Room:
        return (
          <Helmet>
            <title property="title">Rooms in Malta | Easy Rent Malta</title>
            <meta
              property="description"
              name="description"
              content="Looking for Rooms in Malta? Easy Rent Malta - The easiest way to find your ideal Rooms in Malta."
            />
            <meta
              name="keywords"
              content="rooms in malta, room rent in malta, single room rent in malta"
            />
            <meta
              property="og:image"
              content="https://www.easyrent.com.mt/img/logo-green.png"
            ></meta>
          </Helmet>
        );

      default:
        return (
          <Helmet>
            <title property="title">
              Long Let Property - Long Let Apartments in Malta | Easy Rent Malta
            </title>
            <meta
              property="description"
              name="description"
              content="Looking for Long Let Property or Long Let Apartments in Malta? Easy Rent Malta - The easiest way to find your ideal Long Let Property or Apartments in Malta."
            />
            <meta
              name="keywords"
              content="long let property, long let property malta, long let apartments malta"
            />
            <meta
              property="og:image"
              content="https://www.easyrent.com.mt/img/logo-green.png"
            ></meta>
          </Helmet>
        );
    }
  }

  return (
    <div style={{ backgroundColor: "#f7f8fa", paddingBottom: 50 }}>
      {getTitleMeta()}

      {mapVisible ? (
        <Map
          filterForm={filterForm}
          pageNumber={pageNumber}
          centerp={mapCenter}
        />
      ) : null}
      <List
        setMapVisible={() => setMapVisible(!mapVisible)}
        filterOptions={filterOptions}
        properties={properties?.data}
        total={properties?.total}
        getProperties={getProperties}
        propertiesLoading={propertiesLoading}
        form={category?.form || location?.state?.form}
        pageNumber={pageNumber}
        setFilterForm={setFilterForm}
        pageNumber={pageNumber}
        category={category}
      />
    </div>
  );
};

export default PropertyListing;
