import React from "react";
import { Button, Image, Card, Icon, Item } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { pageEnum } from "../../Common";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { AwsUrl } from "../../helpers/aws";

const ListItem = (props) => {
  const getThumb = () => {
    return AwsUrl + props.thumbnailImageUrl;
  };

  const [imageUrlLink, setImageUrlLink] = React.useState(getThumb());

  let duration = moment.duration(moment(props.availableDate).diff(moment()));
  const diff = duration.asDays() <= 0 ? "Available Now" : (duration.asDays() <= 60 ? "Available Soon":"Available in Few Months");

  let imageWidth = window.innerWidth / 5.5;
  let imageWidthMobile = window.innerWidth;

  const fixImageError = (imageUrl) => {
    console.log("fixImageError", imageUrl);
    if (imageUrl != null) {
      let extIndex = imageUrl.lastIndexOf(".");
      let newUrl = imageUrl.substring(0, extIndex) + ".jpg";
      console.log("new image url: ", newUrl);
      setImageUrlLink(newUrl);
    }
  };
  
  //<Link target="_blank" to={`/detail/${props.id}/${props.lettingType}`}>
  return (    
    <Link target="_blank" to={`/${props.urlSlug}-${props.id}-${props.lettingType}`}>
      <div className="easy-list-item">
        <Card style={{ width: isMobile ? imageWidthMobile : imageWidth }}>
          {" "}
          {/* 100%  */}
          <Image
            // className="property-item-image"
            src={imageUrlLink}
            wrapped
            ui={false}
            style={{ height: "200px" }}
            onError={() => fixImageError(imageUrlLink)}
          />
          {/* <span className="price">€ 1.500</span> */}
          <Card.Content>
            <Card.Header style={{ color: "#1caa35" }}>
              {props.typeName}
            </Card.Header>
            <Card.Header style={{ marginTop: 5 }}>{props.title}</Card.Header>
            <Card.Meta>
              <Icon
                name="map marker alternate"
                color="green"
                style={{ marginTop: 15 }}
              />{" "}
              {props.locationName}
            </Card.Meta>
            <Card.Description>
              Bedrooms: {props.bedrooms} Bathrooms: {props.bathrooms}
            </Card.Description>
          </Card.Content>
          <div className="badges">
            <Button
              size="mini"
              color={diff === "Available Now" ? "green" : (diff === "Available Soon"? "orange": "teal")}
            >
              {diff}
            </Button>
            <Button floated="right" size="mini" color="black">
              {"Ref: " + props.id}
            </Button>
          </div>
          <Card.Content extra>
            <span style={{ color: "#000", fontSize: "medium" }}>
              {props.priceDaily != null &&
                props.priceDaily !=0 && ( 
                    <>
                      <Icon name="euro" size={"small"} style={{margin: 0}} />
                      {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                        props.priceDaily
                      )}
                      /Day
                    </>
                  )}
              &nbsp;&nbsp;
              {props.price != null &&
                props.price !=0 && ( 
                    <>
                      <Icon name="euro" size={"small"} style={{margin: 0}} />
                      {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                        props.price
                      )}
                      {document.location.pathname.includes('sales') ? '' : '/Month'}
                    </>
                )}
              &nbsp;&nbsp;
              {props.priceYearly != null &&
                props.priceYearly !=
                  0 && (
                    <>
                      <Icon name="euro" size={"small"} style={{margin: 0}} />
                      {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                        props.priceYearly
                      )}
                      /Year
                    </>
                  )}
            </span>
          </Card.Content>
        </Card>
      </div>
    </Link>
  );
};

export default ListItem;
