import React from "react";
import { Button, Container, Grid, Image, Card, Icon } from "semantic-ui-react";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"
import moment from "moment";
import { pageEnum } from "../../Common";

import { AwsUrl } from "../../helpers/aws";

function PropertiesSlider({ items, title, lettingType }) {
  function getAvailable(date) {
    let duration = moment.duration(moment(date).diff(moment()));
    return duration.asDays() <= 0 ? "Available Now" : (duration.asDays() <= 60 ? "Available Soon":"Available in Few Months");
  }

  function getPrice(long, short, commercional, lettingType) {
    let temp = 0;
    if (lettingType == pageEnum.LongLets) {
      temp = long;
    } else if (lettingType == pageEnum.CommercialLets) {
      temp = commercional;
    } else {
      temp = short;
    }
    return temp;
  }

  function getLettingType(lettingType) {
    switch (lettingType) {
      case pageEnum.CommercialLets:
        return "Commercials";
      case pageEnum.LongLets:
        return "Long Lets";
      case pageEnum.ShortLets:
        return "Short Lets";
      case pageEnum.ForSale:
        return "For Sale";
      case pageEnum.Room:
        return "Room";
      default:
        return "";
    }
  }

  function getRedirect() {
    switch (lettingType) {
      case pageEnum.CommercialLets:
        return "/commercials";
      case pageEnum.LongLets:
        return "/long-lets";
      case pageEnum.ShortLets:
        return "/short-lets";
      case pageEnum.ForSale:
        return "/sales";
      default:
        return "/sales";
    }
  }

  const renderItem = (item, index) => {
    return (
      <div className="p-slider-item" key={index}>
        <a
          href={"/detail/" + item.id + "/" + item.lettingType}
          className="related"
        >
          <Card>
            <Image
              src={AwsUrl + item.thumbnailImageUrl}
              wrapped
              ui={false}
              style={{ height: "200px" }}
            />
            <Card.Content>
              <Card.Header style={{ color: "#1caa35" }}>
                {item.typeName}
              </Card.Header>

              <Card.Header style={{ marginTop: 5 }}>{item.title}</Card.Header>

              <Card.Meta>
                <Icon name="map pin" color="green" style={{ marginTop: 15 }} />{" "}
                {item.locationName}
              </Card.Meta>
              <Card.Description>
                Bedrooms: {item.bedrooms} Bathrooms: {item.bathrooms}
              </Card.Description>
            </Card.Content>

            <Card.Content>
              {/* <a style={{ color: '#000', fontSize: 'large' }}><Icon name='euro' />
                {Intl.NumberFormat('de-DE', { style: 'decimal' }).format(item.price)}</a> */}

              <span style={{ color: "#000", fontSize: "medium" }}>
                {item.priceDaily != null && item.priceDaily != 0 && (
                  <>
                    <Icon name="euro" size={"small"} style={{ margin: 0 }} />
                    {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                      item.priceDaily
                    )}
                    /Day
                  </>
                )}
                &nbsp;&nbsp;
                <Icon name="euro" size={"small"} style={{ margin: 0 }} />
                {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                  item.price
                )}
                {item.lettingType != 4 ? '/Month' : ''} &nbsp;&nbsp;
                {item.priceYearly != null && item.priceYearly != 0 && (
                  <>
                    <Icon name="euro" size={"small"} style={{ margin: 0 }} />
                    {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                      item.priceYearly
                    )}
                    /Year
                  </>
                )}
              </span>
            </Card.Content>

            <div className="badges">
              <Button size="mini" color="black">
                {getLettingType(item.lettingType)}
              </Button>
              <Button
                size="mini"
                color={getAvailable(item.availableDate) === "Available Now" ? "green" : (getAvailable(item.availableDate) === "Available Soon"? "orange": "teal")}
              >
                {getAvailable(item.availableDate)}
              </Button>
            </div>
          </Card>
        </a>
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : items.length > 3 ? 4 : items.length,
    slidesToScroll: isMobile ? 1 : items.length > 3 ? 4 : items.length,
  };

  return (
    <Container style={{ marginTop: 30 }}>
      <Grid>
        <Grid.Column computer="10">
          <h2>{title}</h2>
        </Grid.Column>
        {/* <Grid.Column computer="6">
          <a href={getRedirect()}>
            <h4 style={{ textAlign: 'right' }}>View All Properties</h4>
          </a>
        </Grid.Column> */}
      </Grid>

      <Slider {...settings} style={{ marginTop: 20 }}>
        {items?.map((item, index) => renderItem(item, index))}
      </Slider>
    </Container>
  );
}

export default PropertiesSlider;
