import React from "react";
import Detail from "../detail/index";
import api from "../../api";
import PropertyListing from "../propertyListing";
import { pageEnum } from "../../Common";
import { getQueryParam } from "../../helpers/common";

function DynamicPage() {
  const [state, setState] = React.useState({
    ready: false,
    type: "", //listing, detail
    lettingType: 0,
    propertyId: 0,
    category: null,
  });

  const ref = getQueryParam("ref", document.location.href);
  const slug = document.location.pathname.substring(1);
  const params = slug.split("-")?.filter((s) => s !== "");
  let id, type;

  //#region OLD URLs REDIRECT TO NEW SLUG
  const redirectToProperty = async (propertyId, type) => {
    const res = await api.properties.getPropertiesDetail(propertyId, type);
    if (res && res.result && res.result.property) {
      debugger;
      if (ref) {
        window.location =
          "/" +
          res.result.property.urlSlug +
          "-" +
          propertyId +
          "-" +
          type +
          "?ref=" +
          ref;
      } else {
        window.location =
          "/" + res.result.property.urlSlug + "-" + propertyId + "-" + type;
      }
    }
  };
  //#endregion

  //#region IS CATEGORY CHECK
  const checkCategory = async () => {
    const res = await api.properties.getCategoryBySlug(slug);
    if (res && res.result && res.result.category?.length > 0) {
      let cat = res.result.category[0];
      let bedrooms = [];
      let bathrooms = [];
      if (cat.bedroomsMin > 0 || cat.bedroomsMax > 0) {
        for (let r = cat.bedroomsMin; r <= cat.bedroomsMax; r++) {
          bedrooms.push(r);
        }
      }
      if (cat.bathroomsMin > 0 || cat.bathroomsMax > 0) {
        for (let r = cat.bathroomsMin; r <= cat.bathroomsMax; r++) {
          bathrooms.push(r);
        }
      }

      cat.form = {
        lettingType: cat.lettingType,
        propertyTypes: cat.propertyTypes?.map((pt) => pt.propertyTypeId),
        minPrice: cat.priceMin,
        maxPrice: cat.priceMax,
        squareOfMetersMin:
          cat.squareOfMetersMin > 0 ? cat.squareOfMetersMin : null,
        squareOfMetersMax:
          cat.squareOfMetersMax > 0 ? cat.squareOfMetersMax : null,
        rooms: [],
        bedrooms: bedrooms,
        bathrooms: bathrooms,
        areas: cat.locationGroups?.map((lg) => lg.locationGroupId),
        locations: cat.locations?.map((l) => l.location.id),
        features: cat.features?.map((f) => f.featureId),
      };
      setState({
        ready: true,
        type: "listing",
        lettingType: cat.lettingType,
        propertyId: 0,
        category: cat,
      });
    } else {
      window.location = "/404.html";
    }
  };
  //#endregion

  id = params[params.length - 2];
  type = params[params.length - 1];

  React.useEffect(() => {
    const load = () => {
      const oldParams = slug?.split("/")?.filter((x) => x !== "");
      if (oldParams.length === 3 && oldParams[0].toLowerCase() === "detail") {
        redirectToProperty(oldParams[1], oldParams[2]);
      } else {
        if (!parseInt(id) || !parseInt(type)) {
          checkCategory();
        }
      }
    };

    load();
  }, []);

  const updateActiveMenuStyle = (lettingType) => {
    document
      .getElementsByClassName("link active item")[0]
      ?.classList?.remove("active");

    switch (lettingType) {
      case pageEnum.LongLets:
        document
          .getElementById("desktop-menu-item-long-lets")
          ?.classList?.add("active");
        break;
      case pageEnum.ShortLets:
        document
          .getElementById("desktop-menu-item-short-lets")
          ?.classList?.add("active");
        break;
      case pageEnum.CommercialLets:
        document
          .getElementById("desktop-menu-item-commercials")
          ?.classList?.add("active");
        break;
      case pageEnum.ForSale:
        document
          .getElementById("desktop-menu-item-sales")
          ?.classList?.add("active");
        break;
      case pageEnum.Room:
        document
          .getElementById("desktop-menu-item-room")
          ?.classList?.add("active");
        break;
      default:
        break;
    }
  };

  if (state.ready) {
    if (state.type === "listing") {
      updateActiveMenuStyle(state.lettingType);

      return (
        <PropertyListing
          pageNumber={state.lettingType}
          category={state.category}
        />
      );
    }
  }

  return (
    <div>
      <Detail id={id} type={type} />
    </div>
  );
}

export default DynamicPage;

// http://localhost:3000/detail/35118/1
