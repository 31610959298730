import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import { Grid, Container, Icon, Breadcrumb, Form, Message,Button } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha"
import api from '../../api'
import { setRefId, getRefId } from '../../helpers/cookie'
import countryList from 'react-select-country-list'
import PhoneInput from 'react-phone-input-2'
import { Helmet } from 'react-helmet'

const FlatMate = () => {

  const [form, setForm] = React.useState({
    genderType : 0,
    fullName : '',
    phone : '',
    nationality : '',
    age : 0,
    movingDate : null,
    stayPeriodType : 0,
    budgetMax : 0,
    marriageType : 0,
    preferencesType : 0,
    info : '',
    location : '',
    captcha: null
  })

  const [value, setValue] = useState('')
  const options = useMemo(() => countryList().getData(), [])
  const changeHandler = value => {setValue(value)}

  const [selectedGenderType, setSelectedGenderType] = React.useState(0)           // Varsayılan Değeri 1:Male,        2:Female
  const [selectedStayPeriodType, setSelectedStayPeriodType] = React.useState(0)   // Varsayılan Değeri 1: 1-3 Months, 3: 3-6 Months, 6: 6-12 Months, 9: More Than 1 Year
  const [selectedMarriageType, setSelectedMarriageType] = React.useState(0)       // Varsayılan Değeri 1:Single,      2:Couple
  const [selectedPreferencesType, setSelectedPreferencesType] = React.useState(0) // Varsayılan Değeri 1:Single Bed,  2: Double Bed, 3: Does Not Matter
  const [selectedBudgetType, setSelectedBudgetType] = React.useState(0)           // Varsayılan Değeri 1:Single Bed,  450 - 500 - 550 - 600 - 650 - 700
  
  const [captch, setCaptch] = React.useState(true)
  const [captchValue, setCaptchValue] = React.useState('')
  const [isSuccess, setIsSuccess] = React.useState(0)//ilk hali 0, başarılı 1, başarısız 2

  const renderButton = (text, code) => 
  {
    let basic = true, positive = false

    if (text==="Mr"||text==="Mrs") 
    {
      if(selectedGenderType === code)
      {
        basic = false
        positive = true
      }
       return <Button basic={basic} positive={positive} onClick={() => setSelectedGenderType(code)}>{text}</Button>
        
    }

    if (text==="1-3 Months"||text==="3-6 Months"||text==="6-12 Months"||text==="More Than 1 Year") 
    {
      if(selectedStayPeriodType === code)
      {
        basic = false
        positive = true
      }
       return <Button basic={basic} positive={positive} onClick={() => setSelectedStayPeriodType(code)}>{text}</Button>
        
    }

    if (text==="Single"||text==="Couple") 
    {
      if(selectedMarriageType === code)
      {
        basic = false
        positive = true
      }
       return <Button basic={basic} positive={positive} onClick={() => setSelectedMarriageType(code)}>{text}</Button>
        
    }

    if (text==="Single Bed"||text==="Double Bed"||text==="Does Not Matter") 
    {
      if(selectedPreferencesType === code)
      {
        basic = false
        positive = true
      }
       return <Button basic={basic} positive={positive} onClick={() => setSelectedPreferencesType(code)}>{text}</Button>
        
    }

    if (text==="400 €"||text==="450 €"||text==="500 €"||text==="550 €"||text==="600 €"||text==="650 €"||text==="700 €"||text==="750 €"||text==="800 €"||text==="850 €") 
    {
      if(selectedBudgetType === code)
      {
        basic = false
        positive = true
      }
       return <Button basic={basic} positive={positive} onClick={() => setSelectedBudgetType(code)}>{text}</Button>
        
    }
    
  }

  const messagePositive = () => (
    <Message positive>
      <Message.Header>Successful</Message.Header>
      <p>
        Your request has been sent successfully
      </p>
    </Message>
  )

  const messageWarning = () => (
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>Please fill the required fields. <br></br>"Your Name", "Whatsapp Number", "Moving Date", "Location", "Budget"  </p>
    </Message>
  )
  
  const messageNegative = () => (
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>An unexpected error has occurred. Please try again.</p>
    </Message>
  )

  const onChange = (value) => {
    console.log("Captcha value:", value);
    if (value !== null) {
      setCaptchValue(value)
      setCaptch(false)
    } else {
      setCaptch(true)
    }
  }

  React.useEffect(() => {
    const script = document.createElement("script")
    script.async = true

    script.type = "application/ld+json"

    const codeStr = `
    "@context": "https://schema.org",
    "@type": "RealEstateAgent",
    "name": "Easy Rent",
    "image": "https://www.easyrent.com.mt/img/logo-green.png",
    "@id": "",
    "url": "https://www.easyrent.com.mt/",
    "telephone": "99998330",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "420 Triq D'Argens",
      "addressLocality": "Gżira",
      "postalCode": "",
      "addressCountry": "MT"
    } ,
    "sameAs": [
      "https://www.facebook.com/easyrentmt/",
      "https://www.instagram.com/easyrent.malta/",
      "https://www.youtube.com/channel/UCjo81yeSg4l4JJ3qkl79Hfw",
      "https://www.linkedin.com/company/easyrent-malta/"
    ] 
`
    script.innerHTML = codeStr

    document.body.appendChild(script);

    let url = window.location.href
    let ref = url.split('?ref=')

    if (ref.length > 1) {
      setRefId(ref[1])
    }
  }, [])

  const sendNow = async () => {
    setCaptch(true)

    let model = {
      genderType : selectedGenderType,
      fullName : form?.fullName,
      phone : form?.phone,
      nationality : value.label,
      age : form?.age,
      movingDate : form?.movingDate,
      stayPeriodType : selectedStayPeriodType,
      budgetMax : selectedBudgetType,
      marriageType : selectedMarriageType,
      preferencesType : selectedPreferencesType,
      info : form?.info,
      location : form?.location,
      captcha: captchValue
    }

    console.log(model);
    if(model.fullName===null || model.phone===null || model.location===null|| model.movingDate===null|| model.budgetMax<=0)
    {
      setIsSuccess(3);
    }
    else 
    {    
        const result = await api.requestFlatMate.requestFlatMate(model)
        if (result.statusCode === 200) {
          setIsSuccess(1)
          window.scrollTo(0, 0);
        } else {
          setIsSuccess(2)
        }
    }
    setCaptch(false)
  }

  return (
    <Container style={{ paddingTop: '2rem' }}>

      <Helmet>
        <title>Property to Rent Malta | Real Estate Agent in Malta</title>
        <meta name="description" content="Long Lets and Short Lets Apartments in Malta. Easy Rent Malta – One of the best Real Estate agent in Malta. The easiest way to find your ideal property in Malta." />
        <meta name="keywords" content="apartment for rent, rent in malta, real estate in malta, accommodation in malta, long lets apartments, short lets apartments, commercial property, property for sale" />

      </Helmet>

      <Breadcrumb>
        <Breadcrumb.Section link href="/">Home</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Find Flat Mate</Breadcrumb.Section>
      </Breadcrumb>

      <Grid style={{ paddingTop: '5rem', paddingBottom: '10rem' }}>
        <Grid.Column computer={10} mobile={16}>
          <h1>Find Flat Mate</h1>
          <p>
            
          </p>
          
          {isSuccess !== 1 && (
          <Form>
            
            <Grid style={{ margin: 0, padding: 0, width: "100%" }}>
              <Grid.Column width="8" style={{ margin: 0, padding: 0 }}>
              
              </Grid.Column>
              <Grid.Column width="6" floated="right" style={{ padding: 0 }}>
              Marital Status
              </Grid.Column>
            </Grid>

            <Form.Field>
            <Grid style={{ margin: 0, padding: 0, width: "100%" }}>
              <Grid.Column width="8" style={{ padding: 0 }}>
                <div style={{ fontSize: 'x-small' }}>
                {renderButton('Mr', 1)}
                {renderButton('Mrs', 2)}
              </div>
              </Grid.Column>
              <Grid.Column width="6" floated="right" style={{ padding: 0 }}>
                <div style={{ fontSize: 'x-small' }}>
                  {renderButton('Single', 1)}
                  {renderButton('Couple', 2)}
                </div>
              </Grid.Column>
            </Grid>
          </Form.Field>

          <Form.Field>
            <Grid style={{ margin: 0, padding: 0, width: "100%" }}>
              <Grid.Column width="8" style={{ padding: 0 }}>
                <input required name="fullName"  id="fullName" placeholder='Your Name' value={form?.fullName} onChange={event => setForm({ ...form, fullName: event.currentTarget.value })} />
              </Grid.Column>
              <Grid.Column width="6" floated="right" style={{ padding: 0 }}>
                <input  type="number"   name="age"  id="age"  placeholder="Age" onChange={(e) => {  form.age = e.currentTarget.value; }}/>
              </Grid.Column>
            </Grid>
          </Form.Field>
          
            <Grid style={{ margin: 0, padding: 0, width: "100%" }}>
              <Grid.Column width="8" style={{ margin: 0, padding: 0 }}>
              Whatsapp Number 
              </Grid.Column>
              <Grid.Column width="6" floated="right" style={{ padding: 0 }}>
              Moving Date
              </Grid.Column>
            </Grid>

          <Form.Field>
            <Grid style={{ margin: 0, padding: 0, width: "100%" }}>
              <Grid.Column width="8" style={{ padding: 0 }}>
              <PhoneInput required name="phone"  id="phone"  country={'mt'}  inputProps={{ name: 'phone',    required: true }} value={form?.phone} onChange={phone => setForm({ ...form, phone: phone })} inputStyle={{ paddingLeft: '45px' }}/>
              </Grid.Column>
              <Grid.Column width="6" floated="right" style={{ padding: 0 }}>
              <input required name="movingDate"  id="movingDate" placeholder='Moving Date' min={new Date().toISOString().slice(0, -1)} type="date" value={form?.movingDate} onChange={e => setForm({ ...form, movingDate: e.currentTarget.value })} />
              </Grid.Column>
            </Grid>
          </Form.Field>
          
          <Form.Field>
          Nationality
          <Grid style={{ margin: 0, padding: 0, width: "100%" }}>
              <Grid.Column width="8" style={{ padding: 0 }}>
              <Select options={options} value={value} onChange={changeHandler} />
              </Grid.Column>
              <Grid.Column width="6" floated="right" style={{ padding: 0 }}>
              </Grid.Column>
            </Grid>
          </Form.Field>

          <Form.Field>
          <Grid style={{ margin: 0, padding: 0, width: "100%" }}>
              <Grid.Column width="16" style={{ padding: 0 }}>
                <input required name="location"  id="location" placeholder='Location' value={form?.location} onChange={event => setForm({ ...form, location: event.currentTarget.value })} />
              </Grid.Column>
              <Grid.Column width="0" floated="right" style={{ padding: 0 }}>
              </Grid.Column>
            </Grid>
          </Form.Field>

          <Form.Field>
          Choose your max budget
            <div style={{ fontSize: 'x-small' }}>
              {renderButton('400 €', 400)}
              {renderButton('450 €', 450)}
              {renderButton('500 €', 500)}
              {renderButton('550 €', 550)}
              {renderButton('600 €', 600)}
              <br></br>
              {renderButton('650 €', 650)}
              {renderButton('700 €', 700)}
              {renderButton('750 €', 750)}
              {renderButton('800 €', 800)}
              {renderButton('850 €', 850)}
            </div>
          </Form.Field>
        
          
          <Form.Field>
          How long willing to live?
          <div style={{ fontSize: 'x-small' }}>
            {renderButton('1-3 Months', 1)}
            {renderButton('3-6 Months', 3)}
            {renderButton('6-12 Months', 6)}
            {renderButton('More Than 1 Year', 9)}
          </div>
          </Form.Field>

          

          <Form.Field>
          Preferences
          <div style={{ fontSize: 'x-small' }}>
            {renderButton('Single Bed', 1)}
            {renderButton('Double Bed', 2)}
            {renderButton('Does Not Matter', 3)}
          </div>
          </Form.Field>
          
          <Form.Field>
          <Form.TextArea name="info"  id="info" placeholder='More Info About Yourself' onChange={e => setForm({ ...form, info: e.currentTarget.value })} />
          </Form.Field>
          <ReCAPTCHA
            sitekey="6LdZIDIaAAAAAMmQ0DWPLAlsyPjBtJLk1wc2-4Vo"
            onChange={onChange}
          />
          <Button style={{ marginTop: 10 }} onClick={sendNow} disabled={captch} fluid type='button' color="green">Send Request</Button>
        </Form>
        
          )}
          {
            isSuccess === 1 ? messagePositive() : isSuccess === 2 ? messageNegative() : isSuccess === 3 ? messageWarning() : null
          }
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <h1>ADDRESS</h1>
          <br />
          <Icon name="map pin" /> 420 Triq D'Argens, Il-Gżira
          <br /><br />
          <Icon name="envelope open" /> <a href="mailto:info@easyrent.com.mt">info@easyrent.com.mt</a>
          <br /><br />
          <Icon name="phone" /> <a href="tel:99998330">99998330</a>
          <br /><br />
          <a href="https://www.facebook.com/easyrentmt/" target="_blank"><Icon name="facebook" /></a>
          <a href="https://www.instagram.com/easyrent.malta" target="_blank"><Icon name="instagram" /></a>

          <br /><br />

          <iframe title="title0"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12926.32340799068!2d14.494368!3d35.90828!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x130e4547e11df0ed%3A0xf2230dfc44f4f60a!2s420%20Triq%20D&#39;Argens%2C%20Il-G%C5%BCira!5e0!3m2!1sen!2smt!4v1611011889212!5m2!1sen!2smt" width="400" height="300" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0">

          </iframe>

        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default FlatMate
